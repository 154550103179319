import React from "react";
import { Button,Tooltip } from "../";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-iconbutton";
const sizes = {
	slim: 16,
	regular: 20,
	super: 24,
}

/*---------------------------------------------*/

// validate that props values are valid according to the metadata
function validateProps(props,metadata) {
	const validProps = metadata.props.map((prop) => prop.name);
	for (let prop in props) {
		if (!validProps.includes(prop)) {
			console.error(`Invalid prop '${prop}'`);
		}
	}

	// validate values
	for (let prop in props) {
		const propMetadata = metadata.props.find((p) => p.name === prop);
		if (propMetadata) {
			if (propMetadata.options) {
				if (!propMetadata.options.find((o) => o.value === props[prop])) {
					console.error(`Invalid value '${props[prop]}' for prop '${prop}'`);
				}
			}
		}
	}

	// validate required props
	metadata.props.forEach((prop) => {
		if (prop.required) {
			if (!props[prop.name]) {
				console.error(`Missing required prop '${prop.name}'`);
			}
		}
	});

	// validate prop types
	for (let prop in props) {
		const propMetadata = metadata.props.find((p) => p.name === prop);
		if (propMetadata) {
			if (propMetadata.type) {
				if (typeof props[prop] !== propMetadata.type) {
					console.error(`Invalid type '${typeof props[prop]}' for prop '${prop}'`);
				}
			}
		}
	}
}

function withValidation(Component) {
	const componentWithValidation = function(props) {
		validateProps(props,Component.METADATA);
		return <Component {...props} />
	}

	componentWithValidation.METADATA = Component.METADATA;
	componentWithValidation.displayName = `${Component.METADATA.name}WithValidation`;

	return componentWithValidation;
}

function IconButton({icon='view', tooltipDir='top', ...props}) {
	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	const buttonProps = {
		...props,
		className: classes,
		style: style,
		icon: icon,
		label: null,
		iconSize: props.iconSize || sizes[props.size] || props.size,
	};

	const button = <Button {...buttonProps} />;
	if (props.tooltip) {
		return (
			<Tooltip content={props.tooltip} direction={tooltipDir}>
				{button}
			</Tooltip>
		)
	}

	return button;
}

/*---------------------------------------------*/

IconButton.METADATA = {
	name: "IconButton",
	props: [
		Button.METADATA.props.find((prop) => prop.name === "variant"),
		Button.METADATA.props.find((prop) => prop.name === "size"),
		{
			name: "iconSize",
			type: "number",
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: "tooltipDir",
			type: "string",
			description: "Tooltip direction",
			options: [
				{
					value: "left",
				},
				{
					value: "right",
				},
				{
					value: "top",
				},
				{
					value: "bottom",
				},
			],
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
			description: "Show the component in disabled state.",
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
			description: "Force hover state.",
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
			description: "Force focus state.",
		},
		{
			name: "icon",
			type: "string",
		},
		{
			name: "onClick",
			type: "function",
			description: "Callback function called after the button is clicked",
		},
	],
};

/*---------------------------------------------*/

export default withValidation(IconButton);

