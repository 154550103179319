import React from 'react';
import { useState } from 'react';
import Group from '../group';
import Checkbox from './checkbox.js'

function CheckboxGroup(props) {
	const [value,setValue] = useState(props.value || []);

	const checkboxes = React.Children.map(props.children,(child,index) => {
		const checkboxProps = {
			onChange: onCheckboxChange,
			disabled: props.disabled,
			name: child.props.name || index,
			value: value && (value.indexOf(child.props.name) !== -1)
		};

		return React.cloneElement(child, checkboxProps);
	});

	return (
		<Group disabled={props.disabled} label={props.label}>{checkboxes}</Group>
	)

	/*------------------------------------------*/

	function onCheckboxChange(checkboxValue,checkboxName) {
		const newValue = checkboxValue ? [...value,checkboxName] : value.filter((item) => item !== checkboxName);

		setValue(newValue);
		props.onChange && props.onChange(newValue,props.name);
	}
}

/*---------------------------------------------*/

CheckboxGroup.METADATA = {
	name: 'CheckboxGroup',
	props: [
		{
			name: 'label',
			type: 'string',
			default: 'Checkbox Group label',
			description: 'Label'
		},
		{
			name: 'value',
			type: 'string'
		},
		{
			name: 'disabled',
			type: 'boolean',
			default: false
		},
		{
			name: 'error',
			type: 'boolean',
			default: false
		},
		{
			name: 'forcehover',
			type: 'boolean',
			default: false
		},
		{
			name: 'forcefocus',
			type: 'boolean',
			default: false
		},
		{
			name: 'onChange',
			type: 'function',
			description: 'Callback function called after the value is changed'
		}
	],
	content: {
		default: [
			<Checkbox label='Option 1' name='option1' value='value1' key='value1' />,
			<Checkbox label='Option 2' name='option2' value='value2' key='value2' message="This option has a message" />,
			<Checkbox name='option3' value='value3' key='value3'>Option 3<br/>Multiline</Checkbox>
		]
	}
}

/*---------------------------------------------*/
/*---------------------------------------------*/

export default CheckboxGroup;