import { useEffect, useState, useRef } from "react";

/*---------------------------------------------*/

export { useFocusRemoverOnDisable, useKeyboardFocus };

/*---------------------------------------------*/

/* If the component becomes disabled without losing focus
	(for instance, a Save button that becomes disabled after click)
	the component's tabIndex becomes -1, but this does not make
	it lose focus automatically; we need to manually 'blur' it.
*/

function useFocusRemoverOnDisable(ref) {
	useEffect(() => {
		const element = ref.current;
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === "attributes") {
					if (mutation.attributeName === "tabindex" && element.getAttribute("tabIndex") === "-1") {
						element.blur();
					}
				}
			});
		});

		observer.observe(element, {
			attributes: true,
		});
	});
}

function useKeyboardFocus(ref) {
	const [keyboardFocus, setKeyboardFocus] = useState(false);
	const focusedViaKeyboard = useRef(false);

	useEffect(() => {
		document.body.addEventListener("keydown", onBodyKeydown);

		ref.current.addEventListener("focus", onFocus);
		ref.current.addEventListener("blur", onBlur);

		return () => {
			document.body.removeEventListener("keydown", onBodyKeydown);

			ref.current?.removeEventListener("focus", onFocus);
			ref.current?.removeEventListener("blur", onBlur);
		};
	});

	return [keyboardFocus, setKeyboardFocus];

	/*----------*/

	function onBodyKeydown(event) {
		if (event.key === "Tab") {
			// turn tabClicked on just to indicate that focus needs to be displayed
			focusedViaKeyboard.current = true;
			setTimeout(() => (focusedViaKeyboard.current = false));
		}
	}

	function onFocus(event) {
		if (focusedViaKeyboard.current === true) {
			setKeyboardFocus(true);
		}
	}

	function onBlur(event) {
		setKeyboardFocus(false);
	}
}
