import React from "react";
import useComponentStyling from "../../hooks/useComponentStyling";
import Text from '../text';

const BASE_CLASS = "lth-c-badge";
const DEFAULT_LABEL = 12;

export default function Badge({label='Badge Label', ...props}) {
	const { classNames } = useComponentStyling(BASE_CLASS, {variant: 'common', ...props}, ["variant"]);
	const style = {};
	props.backgroundColor && (style.backgroundColor = props.backgroundColor);

	const textSize = {
		small: 'xsmall',
		regular: 'small',
	}[props.size];

	return (
		<div className={classNames} style={style}>
			<Text size={textSize} color={props.textColor}>{label.length ? label : DEFAULT_LABEL}</Text>
		</div>
	);
}

Badge.METADATA = {
	name: "Badge",
	description: "A Badge",
	props: [
		{
			name: "label",
			type: "string",
			default: "",
			description: "The label to be shown inside the badge",
		},
		{
			name: "variant",
			type: "string",
			default: "common",
			description: "Choose which variant of button to display",
			options: [
				{
					value: "primary",
					description: "For the principal call to action on the page.",
				},
				{
					value: "common",
					description: "For secondary actions on each page.",
				},
				{
					value: "danger",
					description: "",
				},
				{
					value: "warning",
					description: "",
				},
			],
		},
		{
			name: "size",
			type: "string",
			default: "regular",
			options: [
				{
					value: "small",
				},
				{
					value: "regular",
				},
			],
		},
		{
			name: "textColor",
			type: "string",
		},
		{
			name: "backgroundColor",
			type: "string",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
	],
};
