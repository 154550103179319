import React from 'react';
import { useState } from "react";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';
import useRandomId from 'lib/hooks/useRandomId';

import {Label} from '../';
import Thumbnail from './thumbnail.js';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-thumbnails';

/*---------------------------------------------*/

export default function Thumbnails({size='medium',...props}) {
	const [uncontrolledValue,setUncontrolledValue] = useState(null);
	const componentId = useRandomId();

	const classes = useClassBuilder(props,BASE_CLASS,[`${BASE_CLASS}--${size}`]);
	const style = useStyleBuilder(props,{'--num-of-columns': props.columns});

	const value = props.value || uncontrolledValue;
	const label = props.label && (
		<Label disabled={props.disabled}>{props.label}</Label>
	)

	const thumbnails = React.Children.map(props.children,(child,index) => {
		const props = {
			onSelected: onThumbnailSelected,
			selected: value === child.props.value,
			key: index,
			inputName: componentId
		}

		return React.cloneElement(child, props);
	});

	return (
		<div className={classes} style={style}>
			{label}
			<div className={BASE_CLASS+'__container'}>
				{thumbnails}
			</div>
		</div>
	)

	/*------------------------------------------*/

	function onThumbnailSelected(value) {
		setUncontrolledValue(value);
		props.onChange && props.onChange(value,props.name);
	}
}

/*---------------------------------------------*/

Thumbnails.Item = Thumbnail;

Thumbnails.METADATA = {
	name: 'Thumbnails',
	props: [
		{
			name: 'label',
			type: 'string',
			default: 'Choose one item'
		},
		{
			name: 'value',
			type: 'string',
			default: undefined
		},
		{
			name: 'columns',
			type: 'number',
		},
		{
			name: "size",
			type: "string",
			default: "medium",
			description: "Choose the size of button",
			options: [
				{
					value: "xsmall",
				},
				{
					value: "small",
				},
				{
					value: "medium",
				},
				{
					value: "large",
				},
				{
					value: "xlarge",
				},
			],
		},
		{
			name: 'onChange',
			type: 'function',
			description: 'Callback function called when the value was changed'
		}
	],
	content: {
		default: [
			<Thumbnails.Item label='Option 1' value='value1' key='value1' badge="Premium">
				<img src='https://inffuse-testimonials.appspot.com/v2/settings/img/layouts/list.svg' width={20} />
			</Thumbnails.Item>,
			<Thumbnails.Item label='Option 2' value='value2' img='https://inffuse-testimonials.appspot.com/v2/settings/img/layouts/grid.svg' key='value2' />,
			<Thumbnails.Item label='Option 3 asdasd asdasdasd sadasd' value='value3' img='https://inffuse-testimonials.appspot.com/v2/settings/img/layouts/slider.svg' key='value3' />,
			<Thumbnails.Item label='Option 1' value='value21' img='https://inffuse-testimonials.appspot.com/v2/settings/img/layouts/masonry.svg' key='value4' />,
			<Thumbnails.Item label='Option 2' value='value22' img='https://inffuse-testimonials.appspot.com/v2/settings/img/layouts/thumbnail.svg' key='value5' badge="Premium" />,
		]
	}
}