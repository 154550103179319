import React, {useState} from "react";
import Group from "../group";
import RadioButton from "./radiobutton.js";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

const BASE_CLASS = "lth-c-radiobutton-group";

function RadioButtonGroup(props) {
	const [value,setValue] = useState(props.value);

	const buttons = React.Children.map(props.children, (child, index) => {
		if (child.type !== RadioButton) {
			return child;
		}

		const childProps = {
			selected: value === child.props.value,
			onSelected: onButtonSelected,
			inline: props.inline,
			disabled: props.disabled,
		};

		return React.cloneElement(child, childProps);
	});

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);
	
	return (
		<Group className={classes} style={style} label={props.label} disabled={props.disabled} inline={props.inline}>
			{buttons}
		</Group>
	)
	
	/*------------------------------------------*/

	function onButtonSelected(value) {
		setValue(value);
		props.onChange && props.onChange(value,props.name);
	}
}

/*---------------------------------------------*/

RadioButtonGroup.METADATA = {
	name: "Radiobuttongroup",
	props: [
		{
			name: "label",
			type: "string",
			default: "Radio Button Group label",
			description: "Label",
		},
		{
			name: "value",
			type: "string",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "inline",
			type: "boolean",
			default: false,
		},
		{
			name: "error",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
		{
			name: "onChange",
			type: "function",
			description: "Callback function called after the value is changed",
		},
	],
	content: {
		default: [
			<RadioButton label="Option 1" value="value1" key="value1" />,
			<RadioButton label="Option 2" value="value2" key="value2" />,
			<RadioButton label="Option 3" value="value3" key="value3" />,
		],
	},
};

/*---------------------------------------------*/
/*---------------------------------------------*/

export default RadioButtonGroup;
