import React from "react";
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

export default function Caption(props) {
	const BASE_CLASS = "lth-c-caption";
	
	let color = props.color;
	if (color && color.startsWith("--")) {
		color = `var(${color.replace("--", "--color-")})`;
	}

	let customStyle = {};
	if (color) {
		customStyle.color = color;
	}

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props,customStyle);

	return (
		<span className={classes} style={style}>
			{props.children}
		</span>
	);
}

/*---------------------------------------------*/

Caption.METADATA = {
	name: "Caption",
	props: [
		{
			name: "color",
			type: "string",
		},
	],
	demo: {
		content: "Caption example",
	},
};
