import React, {useEffect,useState,useRef} from 'react';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';
import {Button,Checkbox,CheckboxGroup,Input,RadioButtonGroup,RadioButton,Stack} from '../';

/*---------------------------------------------*/

export default function Form(props) {
	const BASE_CLASS = 'lth-c-form';

	const [uncontrolledValues,setUncontrolledValues] = useState(props.values || {});
	const [submitError,setSubmitError] = useState(null);
	const [loading,setLoading] = useState(false);
	const [isValid,setIsValid] = useState(false);
	const [isDirty,setIsDirty] = useState(false);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const values = props.values || uncontrolledValues;
	const submitPromise = useRef(null);

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	const errors = props.errors || {};
	const error = props.error ?? submitError;

	const children = wrapWithForm(props.children);

	return (
		<form className={classes} style={style} onSubmit={onSubmit}>
			{error && !props.silent ? <div style={{marginBottom: 16, color: 'var(--color-error-200)'}}>{error}</div> : null}
			{children}
		</form>
	)

	/*------------------------------------------*/

	function get(obj,path,default_value) { 
		try {
			// https://stackoverflow.com/a/40270942/390035
			const value = path.split('.').reduce((res,key) => res[key], obj);
			if (typeof value !== 'undefined')
				return value;
	
		} catch(error) {
		}
	
		return default_value;
	}
	
	function set(obj,path,value) {
		const nodes = (typeof path === 'string') ? path.split('.') : path;
		const first = nodes.shift();
		if (typeof obj[first] === 'undefined') {
			obj[first] = {};
		}
		
		if (nodes.length) {
			return set(obj[first],nodes,value);
		}
		
		obj[first] = value;
	}
	
	/*------------------------------------------*/

	function onInputChange(value,name) {
		const newValues = {...values};
		set(newValues,name,value);

		props.onChange && props.onChange(newValues,props.name);
		setUncontrolledValues(newValues);

		setIsValid(validate(props.children));
		setIsDirty(true);

		function validate(children) {
			for (let i=0; i < children.length; i++) {
				const child = children[i];
				if (child?.props?.required && !values[child.props.name]) {
					return false;
				}
				
				return child?.props?.children ? validate(child.props.children) : true;
			};
		}
	}

	/*------------------------------------------*/

	function onSubmit(e) {
		e.preventDefault();
		setSubmitError(null);

		submitPromise.current = props.onSubmit && props.onSubmit(values);
		if (submitPromise.current) {
			setLoading(true);
			
			submitPromise.current
				.catch(error => setSubmitError(error || 'Error'))
				.finally(() => setLoading(false));
		}
	}

	/*------------------------------------------*/

	function wrapWithForm(children) {
		return React.Children.map(children,(child,index) => {
			if (!child || !child.props) {
				return child;
			}

			const childProps = {
				children: wrapWithForm(child.props.children)
			}

			if (child.props.name) {
				const name = child.props.name;

				childProps.value = get(values,name,child.props.value);
				childProps.error = !!errors[name] || child.props.error;
				childProps.message = errors[name] || child.props.message;

				childProps.onChange = ((value,name) => {
					onInputChange(value,name); // should we use child.props.name instead?
					child.props.onChange && child.props.onChange(value,name);
				})
			}

			Object.keys(child.props).forEach(key => {
				if (child.props[key] === Form.loading) {
					childProps[key] = loading;
				}

				if (child.props[key] === Form.valid) {
					childProps[key] = isValid;
				}

				if (child.props[key] === Form.dirty) {
					childProps[key] = isDirty;
				}

				if (child.props[key] === Form.notDirty) {
					childProps[key] = !isDirty;
				}
			});

			return React.cloneElement(child, childProps);
		});
	}

	/*------------------------------------------*/

	function handleBeforeUnload(event) {
		if (props.unsavedChangesWarning && isDirty) {
			event.preventDefault();
			event.returnValue = props.unsavedChangesWarning || 'You have unsaved changes!';
		}
	}
}

/*---------------------------------------------*/

Form.loading = 'FORM-LOADING-PLACEHOLDER';
Form.valid = 'FORM-VALID-PLACEHOLDER';
Form.dirty = 'FORM-DIRTY-PLACEHOLDER';
Form.notDirty = 'FORM-NOT-DIRTY-PLACEHOLDER';

/*---------------------------------------------*/

Form.METADATA = {
	name: 'Form',
	props: [
		{
			name: 'name',
			type: 'string',
		},
		{
			name: 'unsavedChangesWarning',
			type: 'string',
		},
		{
			name: "error",
			type: "string",
		},
		{
			name: 'onChange',
			type: 'function'
		},
		{
			name: 'onSubmit',
			type: 'function'
		},
	],
	get demo() {
		// for some reason when adding demo as a regular member React complaints about type error
		return {
			content: (
				<Stack>
					<Input type="text" label="Your name" name='person.name' key='name' />
					<Input type="number" label="Your age" name='person.age' key='age' />
					<Input type="textarea" label="Your notes" name='person.notes' submitOnEnter key='notes' />
					<Button label="Submit" disabled={Form.notDirty} key='submit' />
					<RadioButtonGroup label="Radio button group" value={true} name='radio'>
						<RadioButton label="Option 1" value={true} />
						<RadioButton label="Option 2" value={false} />
					</RadioButtonGroup>
					<CheckboxGroup label="Checkbox group" name='checkbox'>
						<Checkbox label="Option 1" name='option1' value='value1' />
						<Checkbox label="Option 2" name='option2' value='value2' />
					</CheckboxGroup>
				</Stack>
			)
		}
	}
}