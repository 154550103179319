import React,{useState} from "react";
import {ControlMessage, Icon, Input, Label, Text} from "../";

/* Because OverflowMenu.Item.METADATA is accessed immediately when this module is imported 
   (as opposed to being accessed only when the component is used) this needs not to be 
	imported from the ../index.js as it creates a circular import loop - 
	../index.js import select.js, and select.js tries to import from ../index.js which
	is not ready yet. So instead we'll import directly from ../overflowmenu
	[Sasha] */
import OverflowMenu from "../overflowmenu";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

// TODO: BUG: set value via prop & change select value. both selected value and placeholder appear
const BASE_CLASS = "lth-c-select";

/*---------------------------------------------*/

function Select(props) {
	const [index,setIndex] = useState(-1);
	const [isMenuOpen,setIsMenuOpen] = useState(null);
	const [comboInputValue,setComboInputValue] = useState(null);
	
	// We need to create a new key each time the native select value is changed,
	// otherwise an invalid selection may remain on the select element.
	// Example - select valueX, change the props.value to be invalid, then select valueX again.
	// Both the select value and the placeholder will be displayed.
	const [selectVersion,setSelectVersion] = useState(0);

	const customClasses = [`${BASE_CLASS}--${props.size || "normal"}`];
	const iconType = isMenuOpen ? "ChevronUp" : "ChevronDown";
	const icon = (
		<Icon
			className={`${BASE_CLASS}__icon`}
			size={20}
			icon={iconType}
			color={`var(--select-input-${props.disabled ? 'disabled-arrow-color' : 'arrow-color'}`}
		/>
	);

	const isControlled = typeof props.value !== "undefined" && props.value !== "";
	const selectedIndex = isControlled
		? props.children.findIndex((option) => option.props.value === props.value)
		: index;
	const hasValue = selectedIndex >= 0;

	const options = React.Children.map(props.children, (item,index) => {
		return React.cloneElement(item, {
			onClick: item.props.onClick || props.onItemClick,
			selected: (index === selectedIndex) ? true : undefined
		});
	});

	const placeholderText = props.placeholder || "Choose Option";
	const placeholder = <span className={BASE_CLASS + "__input__placeholder"}>{placeholderText}</span>;

	const control = ((type) => {
		if (type === "native") {
			const selectOptions = options.map((option, idx) => {
				return (
					<option value={idx} key={idx} selected={selectedIndex === idx}>
						{option.props.label}
					</option>
				);
			});

			return (
				<div className={BASE_CLASS + "__container"}>
					<select
						className={BASE_CLASS + "__input"}
						onChange={onNativeSelect}
						tabIndex={0}
						disabled={props.disabled}
						key={selectVersion}
					>
						<option
							disabled={hasValue}
							hidden={hasValue}
							style={{ display: "none" }}
							selected={!hasValue}
							value=""
						></option>
						{selectOptions}
					</select>
					{hasValue ? null : placeholder}
					{icon}
				</div>
			);
		}

		const valueText = hasValue ? options[selectedIndex].props.label || options[selectedIndex].props.children : undefined;

		const input = (() => {
			if (type === "combo") {
				return (
					<Input
						value={comboInputValue || valueText}
						error={props.error}
						onChange={onComboInputChange}
						placeholder={placeholderText}
					/>
				);
			}

			const value = hasValue 
				? <span className={BASE_CLASS + "__input__value"}>{valueText}</span>
				: placeholder;

			const textProps = {
				disabled: props.disabled,
			}

			if (!props.disabled) {
				textProps.color = hasValue ? "--neutal-90" : "--neutral-500";
			}

			return (
				<div className={`${BASE_CLASS}__input ${BASE_CLASS}__container`} tabIndex={0}>
					<Text {...textProps}>{value}</Text>
					{icon}
				</div>
			);
		})();

		return (
			<OverflowMenu
				onMenuToggle={onMenuToggle}
				onItemClick={onSelect}
				target={input}
				fullwidth={props.fullwidth}
				disabled={props.disabled}
			>
				{options}
			</OverflowMenu>
		);
	})(props.type || "custom");

	const label = props.label ? <Label disabled={props.disabled} tooltip={props.tooltip} tooltipDir={props.tooltipDir}>{props.label}</Label> : null;
	const message = props.message && <ControlMessage disabled={props.disabled} error={props.error}>{props.message}</ControlMessage>;
	
	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	return (
		<div className={classes} style={style}>
			{label}
			{control}
			{message}
		</div>
	);

	/*------------------------------------------*/

	function onSelect(index, value) {
		setIndex(index);
		setComboInputValue(null);

		props.onChange && props.onChange(value, props.name);
	}

	function onNativeSelect(e) {
		setSelectVersion(selectVersion+1);
		onSelect(1 * e.target.value, props.children[1 * e.target.value].props.value);
	}

	function onComboInputChange(value) {
		setComboInputValue(value);
	}

	function onMenuToggle(isOpen) {
		setIsMenuOpen(isOpen);
	}
}

/*---------------------------------------------*/

Select.Option = (props) => <OverflowMenu.Item {...props} />;
Select.Option.METADATA = {
	...OverflowMenu.Item.METADATA,
	name: "Select.Option",
};

Select.Separator = (props) => <OverflowMenu.Separator {...props} />;
Select.Separator.METADATA = { name: "Select.Separator" };

/*----*/

Select.METADATA = {
	name: "Select",
	description: "",
	props: [
		{
			name: "label",
			type: "string",
			default: "Choose an option",
		},
		{
			name: "value",
			type: "string",
			description: "Selected value",
		},
		{
			name: "placeholder",
			type: "string",
		},
		{
			name: "options",
			type: "string",
			description: "Options to select from",
		},
		{
			name: "type",
			type: "string",
			default: "custom",
			options: [
				{
					value: "custom",
				},
				{
					value: "native",
				},
				{
					value: "combo",
				},
			],
		},
		{
			name: "size",
			type: "string",
			default: "normal",
			options: [
				{
					value: "small",
				},
				{
					value: "normal",
				},
				{
					value: "large",
				},
			],
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: "onChange",
			type: "function",
			description: "Callback function called when the value was changed",
		},
		{
			name: "fullwidth",
			type: "boolean",
			default: false,
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "error",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
		{
			name: "message",
			type: "string",
		},
	],
	content: {
		// default: [...new Array(30)].map((_,i) => <Select.Option label={`Option ${i+1}`} disabled value={`value${i+1}`} />)
		default: [
			<Select.Option value={0} label="label1" />,
			<Select.Option value={1} label="label2" />,
			<Select.Option value={-1} label="label3" />,
		]
	},
	// demo: {
	// 	component: (
	// 		<Select path='weekStart' label="Week start" placeholder="Choose day" value={1}>
	// 			<Select.Option value={0} label="label1" />
	// 			<Select.Option value={1} label="label2" />
	// 			<Select.Option value={-1} label="label3" />
	// 		</Select>
	// 	)
	// }
};

/*---------------------------------------------*/

export default Select;

/*---------------------------------------------*/
