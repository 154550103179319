import React from "react";
import { Tabs } from "../../../../lib/components";
import S from "./TopTabs.module.scss";
import AddNewEvent from "../settings-pages/AddNewEvent/AddNewEvent";
import SamplePage from "../settings-pages/SamplePage/SamplePage";

export default function TopTabs() {
	return (
		<div className={S.container}>
			<Tabs>
				<Tabs.Tab label="Add Event" id="Add Event">
					<AddNewEvent />
				</Tabs.Tab>

				<Tabs.Tab label="2" id="Tab #2">
					<SamplePage />
				</Tabs.Tab>

				<Tabs.Tab label="a longer tab name than usual" id="Another">
					<SamplePage />
				</Tabs.Tab>
			</Tabs>
		</div>
	);
}
