import React, { useMemo, useState } from "react";
import useComponentStyling from "../../hooks/useComponentStyling";

import { Badge } from "..";

const BASE_CLASS = "lth-c-tabs";

function Tab({ children }) {
	return children || null;
}
Tab.METADATA = {
	name: "Tabs.Tab",
};

function Tabs(props) {
	const { children: tabs, selected, onChange } = props;
	const selectedIndexFromProp = useSelectedIndex(selected, tabs);
	const [selectedIndexFromState, setSelectedIndex] = useState(selectedIndexFromProp);
	const selectedIndex = selected !== undefined ? selectedIndexFromProp : selectedIndexFromState;
	const { buttons, tabsContent } = buildTabs(tabs, selectedIndex, selectTab);
	const { style, classNames } = useComponentStyling(BASE_CLASS, props, ["density", "size", "type"]);

	return (
		<div style={style} className={classNames}>
			<div className={`${BASE_CLASS}__buttons`} tabIndex={0} onKeyDown={keyDown}>
				{buttons}
			</div>
			<div className={`${BASE_CLASS}__content`}>{tabsContent}</div>
		</div>
	);

	function selectTab(event, index, id) {
		// event.preventDefault();

		setSelectedIndex(index);
		if (onChange) {
			onChange(index, id);
		}
	}

	function keyDown(event) {
		const { key } = event;
		if (["ArrowLeft", "ArrowRight"].includes(key)) {
			const upcomingIndex = getUpcomingIndex(selectedIndex, tabs, key === "ArrowLeft");
			setSelectedIndex(upcomingIndex);
		}
	}
}

function getUpcomingIndex(currIndex, tabs, getPrev) {
	const start = getPrev ? 0 : currIndex + 1;
	const end = getPrev ? currIndex : tabs.length;

	// when selected index is at the edge of the group
	if (start === end) {
		return currIndex;
	}

	const upcomingTabs = tabs.slice(start, end);

	if (getPrev) {
		upcomingTabs.reverse();
	}

	const upcomingIndex = tabs.indexOf(
		upcomingTabs.find((tab) => {
			return !tab.props.disabled;
		})
	);

	return upcomingIndex;
}

function useSelectedIndex(selected, tabs) {
	const tabsIds = tabs.map((tab) => tab.props.id);

	const selectedIndex = useMemo(() => {
		let index = 0;
		if (typeof selected === "string") {
			index = tabsIds.indexOf(selected);
		} else if (typeof selected === "number") {
			index = selected;
		}

		return index;
	}, [selected, JSON.stringify(tabsIds)]);

	return selectedIndex;
}

function buildTabs(tabs, selectedIndex, buttonClicked) {
	const buttons = [];
	const tabsContent = [];

	tabs.forEach((tab, index) => {
		const isSelected = selectedIndex === index;

		buttons.push(
			<TabButton
				key={index}
				{...tab.props}
				tab={tab}
				index={index}
				selected={isSelected}
				selectedIndex={selectedIndex}
				buttonClicked={buttonClicked}
			/>
		);
		tabsContent.push(<TabContent tab={tab} index={index} selected={isSelected} key={index} />);
	});

	return {
		buttons,
		tabsContent,
	};
}

function TabButton(props) {
	const { tab, index, buttonClicked, selected } = props;

	const { classNames } = useComponentStyling(`${BASE_CLASS}__button`, props, ["selected"]);

	const { badge } = tab.props;
	return (
		<div className={`${BASE_CLASS}__button-container`} key={index}>
			<button
				className={classNames}
				index={index}
				type="button"
				tabIndex={-1}
				onClick={(e) => buttonClicked(e, index, tab.props.id)}
				disabled={tab.props.disabled}
			>
				{tab.props.label}
				{badge ? (
					<Badge label={badge} size="small" variant={selected ? "primary" : "common"} disabled={tab.props.disabled} />
				) : null}
			</button>
		</div>
	);
}

function TabContent({ tab, index, selected }) {
	const dynamicStyle = {
		display: selected ? "block" : "none",
	};

	return (
		<div style={dynamicStyle} key={index}>
			{tab.props.children}
		</div>
	);
}

Tabs.Tab = Tab;
Tabs.METADATA = {
	name: "Tabs",
	description: "Use to alternate among related views within the same context.",
	props: [
		{
			name: "type",
			type: "string",
			description: "choose between 2 types of tab width - fixed, or content-fit",
			category: "style",
			default: "content-fit",
			options: [
				{
					value: "content-fit",
					label: "content-fit",
				},
				{
					value: "fixed",
					label: "fixed",
				},
			],
		},
		{
			name: "density",
			type: "string",
			description: "choose between 2 types of tab height - regular, or compact",
			category: "style",
			default: "regular",
			options: [
				{
					value: "regular",
					label: "regular",
				},
				{
					value: "compact",
					label: "compact",
				},
			],
		},
		{
			name: "size",
			type: "string",
			description: "choose between two options of font size - medium or small",
			category: "style",
			default: "medium",
			options: [
				{
					value: "medium",
					label: "medium",
				},
				{
					value: "small",
					label: "small",
				},
			],
		},
		{
			name: "selected",
			type: "string",
			description: "Set selected tab",
			category: "value",
			options: [
				{
					value: undefined,
					label: "Not set",
				},
				{
					value: 0,
					label: "Tab 1",
				},
				{
					value: 1,
					label: "Tab 2",
				},
				{
					value: 2,
					label: "Tab 3",
				},
				{
					value: "tab1",
					label: "Tab 1 via id",
				},
				{
					value: "tab2",
					label: "Tab 2 via id",
				},
				{
					value: "tab3",
					label: "Tab 3 via id",
				},
			],
		},
	],
	content: {
		default: [
			<Tabs.Tab id="tab1" label="Tab 1 very very very long Label">
				<p>Tab 1 Content</p>
			</Tabs.Tab>,
			<Tabs.Tab id="tab2" label="2" disabled>
				<p>Tab 2 Content</p>
			</Tabs.Tab>,
			<Tabs.Tab id="tab3" label="medium 3" badge="24">
				<p>Tab 3 Content</p>
			</Tabs.Tab>,
		],
	},
};

export default Tabs;
