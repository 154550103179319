import React from 'react';

import { mergeSpecificKeys } from "../../utils/general.js";
import { colorToken } from "../../utils/styleUtils.js";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-spinner";

/*---------------------------------------------*/

export default function Spinner({size=24, duration=0.8, ...props}) {
	const viewBoxVal = props.variant === "custom" ? "-4 -4 64 64" : "0 0 100 100";

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	return (
		<div className={classes} style={{'--size': `${size}px`, ...style}}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="transparent"
				style={{animationDuration: `${1*duration}s`}}
				viewBox={viewBoxVal}
			>
				<SpinnerContent variant={props.variant} colors={getColors(props)} />
			</svg>
		</div>
	);
}

function SpinnerContent({ variant, colors, id }) {
	const styleId = `style-def-${id}`;
	const { circleColor, circleColorEnd, arcColor } = colors;

	return variant === "custom" ? (
		<React.Fragment>
			<defs>
				<linearGradient id={styleId} x1="94.086%" x2="16.122%" y1="-7.254%" y2="86.908%">
					<stop offset="0%" stop-color={circleColor} />
					<stop offset="100%" stop-color={circleColorEnd} />
				</linearGradient>
			</defs>
			<g fill="none" fill-rule="evenodd" strokeWidth="8">
				<circle cx="28" cy="28" r="28" stroke={`url(#${styleId})`} />
				<path stroke={arcColor} d="M28 56c15.462 0 28-12.538 28-28"></path>
			</g>
		</React.Fragment>
	) : (
		<circle
			cx="50%"
			cy="50%"
			r="44"
			strokeWidth={10}
			strokeLinecap="butt"
			strokeDasharray={200}
			stroke={circleColor}
		></circle>
	);
}

function getColors(props) {
	return mergeSpecificKeys(
		{
			circleColor: colorToken("neutral-400"),
			circleColorEnd: props.circleColor || colorToken("neutral-400"),
			arcColor: colorToken("neutral-600"),
		},
		props,
		["circleColor", "circleColorEnd", "arcColor"]
	);
}

/*---------------------------------------------*/

Spinner.METADATA = {
	name: "Spinner",
	props: [
		{
			name: "variant",
			type: "string",
			default: "custom",
			options: [
				{
					value: "custom",
				},
				{
					value: "carbon",
				},
			],
		},
		{
			name: "circleColor",
			type: "string",
			category: "style",
		},
		{
			name: "circleColorEnd",
			type: "string",
			category: "style",
		},
		{
			name: "arcColor",
			type: "string",
			category: "style",
		},
		{
			name: "size",
			type: "number",
		},
		{
			name: "duration",
			type: "number",
			category: "style",
		},
	],
};

/*---------------------------------------------*/
