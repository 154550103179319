import React from "react";
import S from "./TopButtons.module.scss";

import { Button } from "../../../../lib/components";

export default function TopButtons() {
	return (
		<div className={S.container}>
			<Button variant="flat">flat</Button>
			<Button variant="common">common</Button>
			<Button variant="primary">primary</Button>
		</div>
	);
}
