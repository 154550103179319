import React, { useState } from "react";
import {Button,Input,NumberInput,Select,Toggle} from "../lib/components";
import "react-notifications/lib/notifications.css";


const PropField = (props) => {
	const fieldProps = {
		label: props.name,
		name: props.name,
		value: props.value,
		onChange: props.onChange,
		tooltip: props.description?.split(' ').map((word,index) => index % 6 === 5 ? [word,<br />] : [word,' ']),
		// tooltipDir: 'left',
	};

	switch (props.type) {
		case "number":
		case "string": {
			if (props.options) {
				let options = props.options.map((option, index) => (
					<Select.Option value={option.value} label={"" + (option.label || option.value)} key={index} />
				));
				return (
					<Select {...fieldProps} fullwidth>
						{options}
					</Select>
				);
			}

			return props.type === "string" ? <Input {...fieldProps} type="text" /> : <NumberInput {...fieldProps} />;
		}

		case "jsx": {
			return <Input {...fieldProps} type="textarea" />;
		}

		case "longtext": {
			return <Input {...fieldProps} type="textarea" />;
		}

		case "boolean": {
			return <Toggle {...fieldProps} fullwidth />;
		}

		case undefined:
		case "array":
		case "function":
			break;

		case "button":
			return <Button onClick={props.action}>{props.name}</Button>

		default:
			alert("Unknown props type - " + props.type);
			break;
	}

	return null;
};

const PropsEditor = (props) => {
	const [values, setValues] = useState(props.values);

	const params = props.params;
	if (!params) {
		return null;
	}

	const fields = params.map((param, index) => {
		return (
			<div className="modifier" key={param.name}>
				<div className="input" key="input">
					<PropField {...param} value={values[param.name]} onChange={onFieldChange} key={index} />
				</div>
			</div>
		);
	});

	return <div>{fields || "None"}</div>;

	/*------------------------------------------*/

	function setFieldValue(name, value) {
		const updatedValues = {
			...values,
			[name]: value,
		};

		setValues(updatedValues);
		return props.onChange && props.onChange(updatedValues);
	};

	function onFieldChange(value, name) {
		return setFieldValue(name, value);
	};
};

export default PropsEditor;
