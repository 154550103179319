import React from "react";
import { Heading } from "../../../../lib/components";
import S from "./SettingsSection.module.scss";

export default function SettingsSection({ title, children }) {
	return (
		<div className={S.container}>
			<Heading variant="heading6" color="--neutral-600">
				{title}
			</Heading>
			{children}
		</div>
	);
}
