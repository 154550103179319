import React from 'react';

import Icon from "../icon";
import Tooltip from "../tooltip";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-label';

/*---------------------------------------------*/

function Label(props) {
	const customClasses = [];
	props.standAlone && customClasses.push('lth-is-standalone');
	props.size && customClasses.push(`${BASE_CLASS}--${props.size}`);

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);
	
	const tooltip = (() => {
		if (!props.tooltip) {
			return <div />;
		}

		const color = props.disabled ? '--label-disabled-tooltip-icon-color' : '--label-tooltip-icon-color';
		
		return (
			<Tooltip content={props.tooltip} direction={props.tooltipDir} >
				<Icon icon="help" size={20} color={`var(${color})`} />
			</Tooltip>
		) 
	})();

	return (
		<label className={classes} style={style} htmlFor={props.for}>
			<div className={`${BASE_CLASS}__content`}>
				{props.children}
			</div>
			{tooltip}
		</label>
	);
}

/*---------------------------------------------*/

Label.METADATA = {
	name: 'Label',
	props: [
		{
			name: "size",
			type: "string",
			default: "normal",
			options: [
				{
					value: "small",
				},
				{
					value: "normal",
				},
				{
					value: "large",
				},
			],
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: "tooltipDir",
			type: "string",
			options: Tooltip.METADATA.props.find((prop) => prop.name === "direction").options,
		}
	],
	demo: {
		content: "Label example",
	},
}

/*---------------------------------------------*/

export default Label;
