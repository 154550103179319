import React,{useEffect,useRef,useState} from "react";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import { ControlMessage, Icon } from "../";
import Label from "../label";

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-input";

/*---------------------------------------------*/

function BasicInput({type='text',size='normal',placeholder='Enter your input',...props}) {
	const ref = useRef(null);
	const [value,setValue] = useState(null);
	const [inputHasFocus,setInputHasFocus] = useState(false);
	const [customHeight,setCustomHeight] = useState(0);
	
	useEffect(() => {
		if (props.autoFocus && ref?.current) {
			ref.current.focus();
		}
	},[props.autoFocus,ref?.current]);

	const customClasses = [`${BASE_CLASS}--${size}`];
	inputHasFocus && customClasses.push("lth-is-focus");
	props.readOnly && customClasses.push("lth-is-readonly");

	const label = props.label && <Label disabled={props.disabled} size={size} tooltip={props.tooltip} tooltipDir={props.tooltipDir}>{props.label}</Label>;
	const message = props.message && <ControlMessage error={props.error} disabled={props.disabled}>{props.message}</ControlMessage>;
	
	let iconLeft = props.iconLeft || <div></div>;
	if (props.iconLeft && typeof props.iconLeft === "string") {
		iconLeft = (
			<Icon
				icon={props.iconLeft}
				className={BASE_CLASS+"__icon"}
				color={props.iconLeftColor}
				onClick={props.onIconLeftClick}
				size={20}
			/>
		);
	}

	let iconRight = props.iconRight || <div></div>; // grid cell filler
	if (props.iconRight && typeof props.iconRight === "string") {
		iconRight = (
			<Icon
				icon={props.iconRight}
				className={`${BASE_CLASS}__icon`}
				color={props.iconRightColor}
				onClick={props.onIconRightClick}
				size={20}
			/>
		);
	}

	const InputTag = type === "textarea" ? "textarea" : "input";

	const htmlProps = Object.fromEntries(HTML_INPUT_ATTRS.map(key => [key,props[key]]).filter(pair => typeof pair[1] !== 'undefined'));
	const inputProps = {
		...htmlProps,
		style: {
			width: props.inputWidth,
			height: customHeight ? customHeight : '',
		},
		type: type,
		id: props.id,
		name: props.name,
		placeholder: placeholder,
		value: props.value !== undefined ? props.value : (value || props.initialValue || ""),
		inputMode: props.inputMode,
		pattern: props.pattern,
		min: props.min,
		max: props.max,
		rows: type === "textarea" ? props.rows : undefined,
		readOnly: props.readOnly ? "readOnly" : undefined,
		tabIndex: 0,
		onFocus: onInputFocus,
		onBlur: onInputBlur,
		onClick: props.onClick,
		onKeyDown: props.onKeyDown,
		onInput: () => props.autoexpand && setCustomHeight(ref.current.scrollHeight),
		onKeyPress: (e) => {
			if (props.submitOnEnter) {
				if (e.which === 13 && !e.shiftKey) {
					e.preventDefault();
					e.target.form.dispatchEvent(new Event("submit", {cancelable: true, bubbles: true}));
				}
			}
		},
	};

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	return (
		<div className={classes} style={style}>
			{label}
	
			<label className={`${BASE_CLASS}__container`} style={{width: props.inputWidth ? "" : "100%"}}>
				{iconLeft}
				<InputTag {...inputProps} className={`${BASE_CLASS}__control`} onChange={onChange} ref={ref} />
				{iconRight}
			</label>

			{message}
		</div>
	);

	/*-----------------------------------------*/

	function onChange(e) {
		const value = e.target.value;
		setValue(value);

		props.onChange && props.onChange(value,props.name);
	}

	function onInputFocus() {
		setInputHasFocus(true);
	}

	function onInputBlur() {
		setInputHasFocus(false);
	}
}

/*---------------------------------------------*/

function PasswordInput(props) {
	const [showPassword,setShowPassword] = useState(false);

	const inputProps = {
		...props,
		iconRight: showPassword ? "ViewOffFilled" : "view",
		type: showPassword ? "text" : "password",
		onIconRightClick: () => setShowPassword(!showPassword),
	};

	return <BasicInput {...inputProps} />;
}

/*---------------------------------------------*/

function NumberInput(props) {
	// change <type=number> to <type=text pattern='..'>?
	// https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/

	const units = props.units;
	const inputProps = {
		...props,
		value: typeof props.value === "string" && units ? props.value.replace(units, "") : props.value,
		onChange: (value, name) =>
			props.onChange && props.onChange(units === undefined ? 1*value : value + units, name),
	};

	return <BasicInput {...inputProps} />;
}

/*---------------------------------------------*/

export default function Input(props) {
	switch (props.type) {
		case "password": {
			return <PasswordInput {...props} />;
		}

		case "number": {
			return <NumberInput {...props} />;
		}

		default:
			return <BasicInput {...props} />;
	}
}

/*---------------------------------------------*/

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
const HTML_INPUT_ATTRS = [
	'accept','alt','autocomplete','autofocus','capture','checked','dirname','disabled','form','formaction',
	'formenctype','formmethod','formnovalidate','formtarget','height','list','max','maxlength','min',
	'minlength','multiple','name','pattern','placeholder','readOnly','required','size','src','step','width'
];

/*---------------------------------------------*/

Input.METADATA = {
	name: "Input",
	props: [
		{
			name: "label",
			type: "string",
			default: "Enter your input",
		},
		{
			name: "value",
			type: "string",
			default: undefined,
		},
		{
			name: "initialValue",
			type: "string",
			default: undefined,
		},
		{
			name: "type",
			type: "string",
			options: [
				{
					value: "text",
				},
				{
					value: "password",
				},
				{
					value: "number",
				},
				{
					value: "textarea",
				},
			],
		},
		{
			name: "size",
			type: "string",
			default: "normal",
			description: "Choose the size",
			options: [
				{
					value: "small",
				},
				{
					value: "normal",
				},
			],
		},
		{
			name: "placeholder",
			type: "string",
			default: "Enter your input",
		},
		{
			name: "units",
			type: "string",
			description: "Units, will be added to the value",
		},
		{
			name: "tooltip",
			type: "string",
		},
		Label.METADATA.props.find((prop) => prop.name === "tooltipDir"),
		{
			name: "autoexpand",
			type: "boolean",
			default: false,
		},
		{
			name: "autoFocus",
			type: "boolean",
			default: false,
		},
		{
			name: "readOnly",
			type: "boolean",
			default: false,
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "error",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
		{
			name: "message",
			type: "string",
		},
		{
			name: "iconLeft",
			type: "string",
		},
		{
			name: "iconRight",
			type: "string",
		},
		{
			name: "rows",
			type: "number",
			category: "style",
		},
		{
			name: "inputWidth",
			type: "string",
			category: "style",
		},
		{
			name: "onChange",
			type: "function",
		},
	],
};
