import React from 'react';
import {forwardRef} from 'react';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import {Icon,Text} from '../';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-toast";

/*---------------------------------------------*/

function ToastInner(props,ref) {

	const customClasses = [BASE_CLASS+'--'+(props.variant||'default')];
	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	const title = props.title ? <Text className={BASE_CLASS+'__title'} color='--neutral-50' bold>{props.title}</Text> : null;
	const content = props.content ? <Text className={BASE_CLASS+'__content'} color='--neutral-50' size="small">{props.content}</Text> : null;

	return (
		<div className={classes} style={style} ref={ref}>
			<Icon className={BASE_CLASS+'__icon'} icon='checkmarkFilled' size={20} />
			<Icon className={BASE_CLASS+'__close'} icon='close' onClick={props.onClose} size={20} />

			{title}
			{content}
		</div>
	)
}

/*---------------------------------------------*/

const Toast = forwardRef(ToastInner);
export default Toast;

/*---------------------------------------------*/

Toast.METADATA = {
	name: 'Toast',
	props: [
		{
			name: "variant",
			type: "string",
			default: "default",
			options: [
				{
					value: "default",
				},
				{
					value: "success",
				},
				{
					value: "error",
				},
			],
		},
		{
			name: "title",
			type: "string",
			default: "Toast Title",
		},
		{
			name: "content",
			type: "string",
			default: "Toast Content",
		},
		{
			name: "onClose",
			type: "function",
		},
	]
}