import React from 'react';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-controlmessage';

/*---------------------------------------------*/

function ControlMessage(props) {
	const customClasses = [];
	props.error && customClasses.push('lth-has-error');
	props.disabled && customClasses.push('lth-is-disabled');
	props.standAlone && customClasses.push('lth-is-standalone');

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);
	
	return (
		<div className={classes} style={style}>
			{props.children}
		</div>
	);
}

/*---------------------------------------------*/

ControlMessage.METADATA = {
	name: 'Control Message',
	props: [
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "error",
			type: "boolean",
			default: false,
		},
	],
	demo: {
		content: "ControlMessage example",
	},
}

/*---------------------------------------------*/

export default ControlMessage;
