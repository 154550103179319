import React,{useEffect,useState} from 'react';
import { CustomPicker } from 'react-color'

import { Label, Icon, OverflowMenu, Text } from '../';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

import { Hue, Saturation, Alpha } from 'react-color/lib/components/common';
import ChromeFields from 'react-color/lib/components/chrome/ChromeFields';
import SketchPresetColors from 'react-color/lib/components/sketch/SketchPresetColors';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-colorpicker';

/*---------------------------------------------*/

function MyPicker(props) {
	const styles = {
		color: {
			background: props.color,
		},
	}

	const presetColors = [
		'#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505',
		'#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000',
		'#4A4A4A', '#9B9B9B', '#FFFFFF'];

	return (
		<div className={`${BASE_CLASS}__picker`}>
			<div className={`${BASE_CLASS}__picker__saturation`}>
				<Saturation
					pointer={props.showPicker ? undefined : () => <div></div>}
					{...props}
				/>
			</div>

			<div className={`${BASE_CLASS}__picker__sliders_container`}>
				<div className={`${BASE_CLASS}__picker__sliders`}>
					<div className={`${BASE_CLASS}__picker__slider`}>
						<Hue hsl={props.hsl} onChange={props.onChange} />
					</div>

					<div className={`${BASE_CLASS}__picker__slider`}>
						<Alpha {...props} onChange={props.onChange} />
					</div>
				</div>

				<div className={`${BASE_CLASS}__picker__color_container`}>
					<div className={`${BASE_CLASS}__picker__color`} style={styles.color} />
					{/* <Icon icon='close' className={`${BASE_CLASS}__picker__details__clear`} onClick={() => props.onReset()} /> */}
				</div>
			</div>

			<div className={`${BASE_CLASS}__picker__details`}>
				<div className={`${BASE_CLASS}__picker__details__fields`}>
					<ChromeFields {...props} />
				</div>

				<div className={`${BASE_CLASS}__picker__details__presets`}>
					<SketchPresetColors colors={ presetColors } onClick={ props.onChange } />
				</div>
			</div>
		</div>
	)
}

const CustomColorPicker = CustomPicker(MyPicker)

/*---------------------------------------------*/

function Colorpicker(props) {
	const [value,setValue] = useState(normalizeValue(props.value));
	const [isOpen,setIsOpen] = useState(false);

	useEffect(() => {
		setValue(normalizeValue(props.value));
	},[props.value]);

	const customClasses = [];
	const controlSize = props.size || props.controlSize;
	controlSize && customClasses.push(`${BASE_CLASS}--${controlSize}`);

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	const styles = {
		panel: {
			display: isOpen ? 'block' : 'none',
		},
		icon: {
			background: value
		}
	}

	const arrowIconType = isOpen ? "ChevronUp" : "ChevronDown";
	
	const iconClasses = [`${BASE_CLASS}__icon`];
	!value && iconClasses.push('lth-is-empty');
	
	const icon = <div className={iconClasses.join(' ')} style={styles.icon}></div>;
	const nbsp = '\u00A0';
	const button = (
		<div className={BASE_CLASS + "__button"} onClick={() => setIsOpen(true)} tabIndex="0">
			{icon}
			<Text className={`${BASE_CLASS}__text`} size="small">{value || nbsp}</Text>
			<Icon
				className={BASE_CLASS+"__arrow"}
				size={16}
				icon={arrowIconType}
			/>
		</div>
	);
	
	const panel = (
		<div className={`${BASE_CLASS}__panel`}>
			<CustomColorPicker color={value} showPicker={!!value} onChange={onPickerChange} onReset={onReset} />
		</div>
	);

	return (
		<div className={classes} style={style}>
			<Label standAlone size={props.size} tooltip={props.tooltip}>{props.label}</Label>
			<div className={BASE_CLASS + "__control"}>
				<OverflowMenu target={button} panel={panel} onMenuToggle={open => setIsOpen(open)} />
			</div>
		</div>
	);

	/*------------------------------------------*/

	function toHex(d) {
		let h = Math.floor(1*d).toString(16);
		if (h.length < 2) {
			h = "0" + h;
		}

		return h;
	}

	function rgbToHex([r,g,b,a=1]) {
		return "#" + [r,g,b,Math.floor(a*255)].map(toHex).join('').toUpperCase();
	}

	function normalizeValue(color) {
		if (!color) {
			return;
		}

		const rgbMatch = color.match(/^rgba?\((.*)\)$/i)
		return rgbMatch ? rgbToHex(rgbMatch[1].split(',').map(s => s.trim())) : color;
	}

	function onPickerChange(color) {
		// color.hex returns TRANSPARENT when alpha=0 and color=#000
		const newValue = rgbToHex([
			color.rgb.r,
			color.rgb.g,
			color.rgb.b,
			color.rgb.a,
		]);
		
		setValue(newValue)
		props.onChange && props.onChange(newValue,props.name);
	}

	function onInputChange(newValue) {
		setValue(newValue);
		props.onChange && props.onChange(newValue,props.name);
	}

	function onReset() {
		onInputChange(undefined);
	}
}

/*---------------------------------------------*/

Colorpicker.METADATA = {
	name: 'Colorpicker',
	props: [
		{
			name: 'label',
			type: 'string',
			default: 'Pick a color'
		},
		{
			name: 'value',
			type: 'string',
			default: ''
		},
		{
			name: 'placeholder',
			type: 'string',
			default: 'Enter hex value'
		},
		{
			name: "size",
			type: "string",
			options: [
				{
					value: "small",
				},
				{
					value: "normal",
				},
			],
		},
		{
			name: "controlSize",
			type: "string",
			description: 'Use this prop to control the size of the control only, without affecting the label size',
			options: [
				{
					value: "small",
				},
				{
					value: "normal",
				},
			],
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: 'disabled',
			type: 'boolean',
			default: false
		},
		{
			name: 'error',
			type: 'boolean',
			default: false
		},
		{
			name: 'message',
			type: 'string'
		},
		{
			name: 'onChange',
			type: 'function'
		}
	]
}

/*---------------------------------------------*/

export default Colorpicker;

/*---------------------------------------------*/
