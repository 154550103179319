import React from "react";
import { Heading } from "../../../lib/components";
// import Avatar from "../../../lib/components/avatar/Avatar";
import S from "./Header.module.scss";

export default function Header() {
	return (
		<div className={S.container}>
			<Heading color="#F6752B">Design System Pilot</Heading>
			{/* <Avatar /> */}
		</div>
	);
}
