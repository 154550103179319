import React from "react";
import {useState,useRef} from "react";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import { ControlMessage, Text } from "../";

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-toggle";

/*---------------------------------------------*/

export default function Toggle(props) {
	const ref = useRef(null);
	const controlledValue = props.value === 'false' ? false : props.value;
	const [value,setValue] = useState(controlledValue);
	
	if (typeof controlledValue !== "undefined" && controlledValue !== "") {
		// if a valid value was passed through props - use that one
		if (value !== controlledValue) {
			setValue(controlledValue);
		}
	}
	
	const customClasses = [];
	props.size && customClasses.push(`${BASE_CLASS}--${props.size}`);
	props.direction && customClasses.push(`${BASE_CLASS}--${props.direction}`);

	value && customClasses.push("lth-is-checked");
	
	const message = props.message && <ControlMessage error={props.error} disabled={props.disabled}>{props.message}</ControlMessage>;
	const label = (typeof props.label === "undefined") ? "Label" : props.label;
	const labelSize = (props.size === "small") ? "small" : "regular";

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	return (
		<div className={classes} style={style} tabIndex={props.disabled ? undefined : 0} onKeyDown={onKeyDown}>
			<label className={BASE_CLASS + "__container"} onClick={onToggle} ref={ref}>
				<div className={BASE_CLASS + "__label"}>
					<Text size={labelSize}>{label}</Text>
				</div>
				<div className={BASE_CLASS + "__track"}>
					<div className={BASE_CLASS + "__handle"} />
				</div>
			</label>
			{message}
		</div>
	);

	/*------------------------------------------*/

	function onToggle(e) {
		if (props.disabled) {
			return;
		}

		const newValue = !value;
		setValue(newValue);
		props.onChange && props.onChange(newValue, props.name);
	}

	function onKeyDown(e) {
		// allow using space to toggle the checkbox value
		if (e.keyCode === 32) {
			e.preventDefault();
			ref.current.click();
		}
	}
}

/*----*/

Toggle.METADATA = {
	name: "Toggle",
	props: [
		{
			name: "label",
			type: "string",
			description: "Input label",
		},
		{
			name: "value",
			type: "string",
			description: "Current value",
			options: [
				{
					value: undefined,
					label: "Not set",
				},
				{
					value: true,
					label: "True",
				},
				{
					value: false,
					label: "False",
				},
				{
					value: 'false',
					label: "False String",
				},
			],
		},
		{
			name: "size",
			type: "string",
			options: [
				{
					value: "medium",
				},
				{
					value: "small",
				},
			],
		},
		{
			name: "direction",
			type: "string",
			description: "The direction of the toggle label relative to the handle",
			options: [
				{
					value: "left",
				},
				{
					value: "right",
				},
				{
					value: "top",
				},
			],
			category: "style",
		},
		{
			name: "fullwidth",
			type: "boolean",
		},
		{
			name: "disabled",
			type: "boolean",
			description: "Set disabled state",
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
			description: "Force focus state.",
		},
		{
			name: "message",
			type: "string",
			description: "Message displayed in the component",
		},
		{
			name: "onChange",
			type: "function",
			description: "Callback function called after the value is changed",
		},
	],
};
