import React from "react";
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import Text from '../text';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-link";

/*---------------------------------------------*/

export default function Link(props) {
	const customClasses = ["lth-body-regular"];
	
	props.inline && customClasses.push(`${BASE_CLASS}--inline`);
	props.monochrome && customClasses.push(`${BASE_CLASS}--monochrome`);

	const customStyle = {};
	if (props.color?.startsWith("--")) {
		customStyle.color = `var(${props.color.replace("--", "--color-")})`;
	}

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props,customStyle);

	const tagProps = {
		className: classes,
		style: style,
		href: props.href,
		onClick: props.onClick,
		tabIndex: 0,
		target: props.target,
	};

	const textProps = {
		size: props.size,
		color: props.color || (props.disabled ? '' : 'var(--link-text-color)'),
		bold: props.bold,
		disabled: props.disabled,
	}

	return (
		<a {...tagProps}>
			<Text {...textProps}>{props.children}</Text>
		</a>
	);
}

/*---------------------------------------------*/

Link.METADATA = {
	name: "Link",
	props: [
		{
			name: "href",
			type: "string",
			default: "#",
		},
		{
			name: "target",
			type: "string",
			options: [
				{
					value: "_self",
					label: "Current tab",
				},
				{
					value: "_blank",
					label: "New tab",
				},
				{
					value: "_parent",
					label: "Parent window",
				},
				{
					value: "_top",
					label: "Top window",
				},
			],
		},
		{
			name: "size",
			type: "string",
			default: "medium",
			options: [
				{
					value: "medium",
					label: "Medium",
				},
				{
					value: "small",
					label: "Small",
				},
			],
		},
		{
			name: "color",
			type: "string",
		},
		{
			name: "onClick",
			type: "function",
		},
		{
			name: "bold",
			type: "boolean",
		},
		{
			name: "inline",
			type: "boolean",
		},
		{
			name: "monochrome",
			type: "boolean",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
	],
	content: {
		default: "Test link",
	},
};
