import React from "react";
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-text";

/*---------------------------------------------*/

export default function Text({size='regular',...props}) {	
	const customClasses = [];
	customClasses.push(`${BASE_CLASS}--${size}`);
	props.bold && customClasses.push(`${BASE_CLASS}--bold`);

	const customStyle = {};
	if (props.color?.startsWith("--")) {
		customStyle.color = `var(${props.color.replace("--", "--color-")})`;
	}

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props,customStyle);

	return (
		<span className={classes} style={style} onClick={props.onClick}>
			{props.children}
		</span>
	);
}

/*---------------------------------------------*/

Text.METADATA = {
	name: "Text",
	props: [
		{
			name: "size",
			type: "string",
			default: "regular",
			options: [
				{
					value: "xsmall",
					label: "Extra Small",
				},
				{
					value: "small",
					label: "Small",
				},
				{
					value: "regular",
					label: "Regular",
				},
				{
					value: "large",
					label: "Large",
				},
			],
		},
		{
			name: "bold",
			type: "boolean",
		},
		{
			name: "color",
			type: "string",
		},
	],
	demo: {
		content: "Body text example",
	},
};
