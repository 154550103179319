import React from 'react';
import {Input,Button} from '../';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-l-stack';

/*---------------------------------------------*/

function StackSpacer({height='m',...props}) {
	const style = {
		height: `var(--space-stack-${height})`,
	}

	return <div className={`${BASE_CLASS}-spacer`} style={style}></div>
}

/*---------------------------------------------*/

export default function Stack({spacing='normal',...props}) {
	const customClasses = [`lth-spacing-${spacing}`];

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	const fieldClassName = `${BASE_CLASS}__field`;
	const fields = React.Children.map(props.children,(child,index,list) => {
		const customSpacing = child.props?.spacing ? 'lth-spacing-'+child.props.spacing : '';
		return (
			<div className={`${fieldClassName} ${customSpacing}`}>
				{child}
			</div>
		)
	});

	return (
		<div className={classes} style={style}>
			{fields}
		</div>
	);
}

Stack.Spacer = StackSpacer;

/*---------------------------------------------*/

Stack.METADATA = {
	name: 'Stack',
	description: 'Use to alternate among related views within the same context.',
	props: [
		{
			name: 'spacing',
			type: 'string',
			description: 'Choose the spacing between the stack elements',
			value: 'normal',
			options: [
				{
					value: 'dense'
				},
				{
					value: 'compact'
				},
				{
					value: 'normal'
				},
				{
					value: 'comfortable'
				},
				{
					value: 'spaced'
				}
			]
		},
	],
	get demo() {
		// for some reason when adding demo as a regular member React complaints about type error
		return {
			content: [
				<Input type="text" label="Your first name" key='first-name' />,
				<Input type="text" label="Your last name" key='last-name' />,
				<Input type="number" label="Your age" key='age' />,
				<Input type="text" label="Your ID" spacing='spaced' key='id' />,
				<Button label="Submit" key='submit' />
			]
		}
	}
}

