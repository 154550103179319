import React from 'react';
import {useMemo,useRef} from 'react';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';
import { ControlMessage, Label } from "../";

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-radiobutton";

/*---------------------------------------------*/

export default function RadioButton(props) {
	const ref = useRef(null);
	const id = useMemo(() => Math.floor(Math.random() * 10000), []);

	const customClasses = [];
	props.inline && customClasses.push(`${BASE_CLASS}--inline`);

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);
	
	const message = props.message && (
		<ControlMessage error={props.error} disabled={props.disabled} style={{marginTop: 'var(--radiobutton-message-spacing-top)'}}>{props.message}</ControlMessage>
	);
	
	let reveal = null;
	if (props.reveal) {
		reveal = <div className={`${BASE_CLASS}__reveal`}>{props.reveal}</div>;
	}

	let label = props.label ?? props.children ?? DEFAULT_LABEL;
	const labelBlock =
		label === null ? null : (
			<div className={`${BASE_CLASS}__label`}>
				<Label standAlone for={id}>
					{label}
				</Label>
				{message}
				{reveal}
			</div>
		);

	const inputProps = {
		type: "radio",
		value: props.value,
		checked: props.selected,
		disabled: props.disabled,
		onChange: onChange,
	};

	return (
		<div className={classes} style={style} tabIndex={props.disabled ? false : 0} onKeyDown={onKeyDown} key={props.value}>
			<label htmlFor={id}>
				<input id={id} className={`${BASE_CLASS}__input`} {...inputProps} ref={ref} />
				<div className={`${BASE_CLASS}__control`}>
					<div className={`${BASE_CLASS}__circle`}>
						<div className={`${BASE_CLASS}__dot`}></div>
					</div>
				</div>

				{labelBlock}
			</label>
		</div>
	);

	/*------------------------------------------*/

	function onChange(e) {
		props.onSelected && props.onSelected(props.value);
	}

	function onKeyDown(e) {
		// allow using space to toggle the checkbox value
		if (e.keyCode === 32) {
			e.preventDefault();
			ref.current.click();
		}
	}

	/*------------------------------------------*/

}

/*---------------------------------------------*/

const DEFAULT_LABEL = "Label";

/*---------------------------------------------*/

RadioButton.METADATA = {
	name: "Radiobutton",
	props: [
		{
			name: "label",
			type: "string",
			description: "Label",
		},
		{
			name: "value",
			type: "string",
			description: "Current value",
		},
		{
			name: "reveal",
			type: "string",
			description: "Reveal additional content when the radio button is selected",
			category: "view",
		},
		{
			name: "disabled",
			type: "boolean",
			description: "Set disabled state",
		},
		{
			name: "error",
			type: "boolean",
			description: "Set error state",
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
			description: "Force hover state.",
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
			description: "Force focus state.",
		},
		{
			name: "message",
			type: "string",
			description: "Message displayed in the component",
		},
		{
			name: "onSelected",
			type: "function",
			description: "Callback function called after the value is changed",
		},
	],
};

/*---------------------------------------------*/
/*---------------------------------------------*/
