import React, { useRef } from "react";
import "react-notifications/lib/notifications.css";
import { useDrag, useDrop } from "react-dnd";
import * as DesignSystem from "../../lib/components";

function childrenCount(node) {
	if (!node.components) {
		return 1;
	}

	return node.components.map((component) => childrenCount(component)).reduce((a, b) => a + b, 1);
}

const Navigator = (props) => {
	if (!props.node.components) {
		return null;
	}

	let index = 1;

	const list = props.node.components.map((component) => {
		const isSelected = props.selected && component.id === props.selected.id;
		const selectedClass = isSelected ? "is-selected" : "";

		const innerProps = {
			...props,
			node: component,
		};

		const content = (
			<div
				className={`list-item ${selectedClass}`}
				index={index}
				onClick={(event) => {
					event.stopPropagation();
					props.onSelected(component);
				}}
			>
				<ComponentLabel component={component} onMove={props.onMove} onDelete={props.onDelete}></ComponentLabel>
				{component && <Navigator {...innerProps} />}
			</div>
		);

		index += childrenCount(component);
		return content;
	});

	return <div className="list">{list}</div>;
};

/*---------------------------------------------*/

const ComponentLabel = (props) => {
	const ref = useRef(null);

	const [, drag] = useDrag({
		item: {
			type: "component",
			component: props.component,
		},
		collect: (monitor) => ({
			opacity: monitor.isDragging() ? 0.5 : 1,
		}),
	});

	const [, drop] = useDrop({
		accept: "component",
		drop: (dragged, monitor) => {
			if (!ref.current) {
				return;
			}

			const boundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (boundingRect.bottom - boundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - boundingRect.top;
			const above = hoverClientY < hoverMiddleY;

			props.onMove(props.component, dragged.component, above);
		},
		// canDrop: () => canMoveKnight(x, y),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
			canDrop: !!monitor.canDrop(),
		}),
	});

	drag(drop(ref));

	let menuIcon = <DesignSystem.Icon icon="OverflowMenuHorizontal" />;
	return (
		<label ref={ref}>
			<DesignSystem.Text>{props.component.type}</DesignSystem.Text>
			<div className="menu" tabIndex={1}>
				<DesignSystem.OverflowMenu target={menuIcon}>
					{/* <DesignSystem.OverflowMenu.Item onClick={() => }>Add Component</DesignSystem.OverflowMenu.Item> */}
					<DesignSystem.OverflowMenu.Item onClick={() => props.onDelete(props.component)} icon="trashCan">
						Delete
					</DesignSystem.OverflowMenu.Item>
				</DesignSystem.OverflowMenu>
			</div>
		</label>
	);
};

export default Navigator;
