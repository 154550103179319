import React, {useState,useRef} from "react";
import { Text } from "../";

import Portal from 'lib/utils/portal';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-tooltip";

/*---------------------------------------------*/

export default function Tooltip({direction='right', containerClass='', ...props}) {
	const [hover,setHover] = useState(false);
	const portalRef = useRef(null);
	const elementRef = useRef(null);

	const customStyle = {
		textAlign: ['top','bottom'].includes(direction) ? 'center' : '',
		'--tooltip-popover-text-color': props.color,
		'--tooltip-popover-bg-color': props.background,
	}
	const style = useStyleBuilder(props,customStyle);

	const portalAlignment = {
		vertical: ['top','bottom'].includes(direction) ? direction : 'center',
		horizontal: ['left','right'].includes(direction) ? direction : 'center',
	}
		
	const directionClass = `${BASE_CLASS}--${direction}`;
	const customClasses = [directionClass];
	const classes = useClassBuilder(props,BASE_CLASS,customClasses);

	const content = (() => {
		if (!hover) {
			return null;
		}

		return (
			<Portal element={elementRef.current} alignment={portalAlignment} reuseId='tooltip' ref={portalRef}>
				<Text className={classes} style={style} size="xsmall">
					{props.content}
				</Text>
			</Portal>
		)
	})();	

	return (
		<div className={`${BASE_CLASS}__container ${containerClass}`} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} ref={elementRef}>
			{props.children}
			{content}
		</div>
	);

	/*------------------------------------------*/

	function onMouseOver() {
		setHover(true);
		props.onShow?.apply(this,[]);
	}

	function onMouseLeave() {
		setHover(false);
		props.onHide?.apply(this,[]);
	}
}

/*---------------------------------------------*/

Tooltip.METADATA = {
	name: "Tooltip",
	props: [
		{
			name: "content",
			type: "string",
			// default: "Here is the tooltip text!",
			default: <span>Here is the tooltip text!<br/>Multiline!</span>,
			category: "value",
		},
		{
			name: "color",
			type: "string",
		},
		{
			name: "background",
			type: "string",
		},
		{
			name: "direction",
			type: "string",
			description: "Tooltip direction",
			options: [
				{
					value: "left",
				},
				{
					value: "right",
				},
				{
					value: "top",
				},
				{
					value: "bottom",
				},
			],
		},
	],
	content: {
		default: (
			<div
				style={{
					background: "var(--color-neutral-100)",
					padding: 16,
				}}
			>
				Hover over me
			</div>
		),
	},
};
