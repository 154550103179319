import React from "react";
import Component from "../component.js";
import { Icon, Input } from "../";

/*---------------------------------------------*/

// TODO: better handle controlled components.
// if the component is controlled - no reason to manage value in state.
// currently, the value is being modified even if externally it's fixed.

/*---------------------------------------------*/

export default class NumberInput extends Component {
	static BASE_CLASS = "lth-c-numberinput";

	constructor() {
		super({
			keyboardFocusOnly: true,
		});

		this.state = {
			value: "",
		};

		/*--*/

		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);

		/*--*/

		this.onCaretMouseDown = this.onCaretMouseDown.bind(this);

		this.onCaretUpMouseUp = this.onCaretUpMouseUp.bind(this);
		this.onCaretDownMouseUp = this.onCaretDownMouseUp.bind(this);

		/*--*/

		this.onChange = this.onChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	increment() {
		this.onChange((1 * this.state.value || 0) + 1);
	}

	decrement() {
		this.onChange((1 * this.state.value || 0) - 1);
	}

	onChange(value) {
		var pattern = /^-?\d*\.?\d*$/;
		if (!pattern.test(value)) return;

		value = Number(value);

		this.setState({ value: value });
		this.props.onChange && this.props.onChange(value, this.props.name);
	}

	onCaretMouseDown(e) {
		// prevent carets (arrows) clicks from affecting the input focus
		e.preventDefault();
	}

	onCaretUpMouseUp() {
		this.increment();
	}

	onCaretDownMouseUp() {
		this.decrement();
	}

	onKeyDown(event) {
		switch (event.key) {
			case "ArrowUp": {
				event.preventDefault();
				this.increment();
				break;
			}

			case "ArrowDown": {
				event.preventDefault();
				this.decrement();
				break;
			}

			default:
				break;
		}
	}

	onFocus(e) {
		this.setState({ keyboardFocus: this.focusedViaKeyboard });
	}

	onBlur() {
		this.setState({
			hoverIndex: 0,
			keyboardFocus: false,
		});
	}

	render() {
		this.resetClassNames();
		this.addClass(this.constructor.BASE_CLASS);
		this.state.keyboardFocus && this.addClass("lth-has-keyboardfocus");

		let value = this.props.value || this.state.value;

		let carets = (
			<div className={this.constructor.BASE_CLASS + "__carets"}>
				<div
					className={this.constructor.BASE_CLASS + "__caret"}
					onMouseDown={this.onCaretMouseDown}
					onMouseUp={this.onCaretUpMouseUp}
				>
					<Icon className="icon" color="inherit" icon="caretUp" size={20} />
				</div>
				<div
					className={this.constructor.BASE_CLASS + "__caret"}
					onMouseDown={this.onCaretMouseDown}
					onMouseUp={this.onCaretDownMouseUp}
				>
					<Icon className="icon" color="inherit" icon="caretDown" size={20} />
				</div>
			</div>
		);

		let inputProps = {
			...this.props,
			type: "text",
			value: value,
			iconRight: carets,
			inputWidth: this.props.digits && `${this.props.digits * DIGIT_WIDTH_EM}em`,
			inputMode: "numeric",
			pattern: "[0-9]*",
			onKeyDown: this.onKeyDown,
			onChange: this.onChange,
		};

		return (
			<div style={this.getStyle()} className={this.getClassNames()} onFocus={this.onFocus} onBlur={this.onBlur}>
				<Input {...inputProps} />
			</div>
		);
	}
}

/*---------------------------------------------*/

const DIGIT_WIDTH_EM = 0.56163;

/*---------------------------------------------*/

NumberInput.METADATA = {
	name: "Numberinput",
	props: [
		{
			name: "label",
			type: "string",
			default: "Enter your input",
		},
		{
			name: "value",
			type: "string",
			default: undefined,
		},
		{
			name: "size",
			type: "string",
			default: "normal",
			description: "Choose the size",
			options: [
				{
					value: "slim",
				},
				{
					value: "normal",
				},
			],
		},
		{
			name: "placeholder",
			type: "string",
		},
		{
			name: "units",
			type: "string",
		},
		{
			name: "digits",
			type: "number",
			category: "view",
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "error",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
		{
			name: "message",
			type: "string",
		},
		{
			name: "onChange",
			type: "function",
		},
	],
};
