import React, { useState, useRef, useLayoutEffect } from "react";
import "./playground.scss";

import reactElementToJSXString from "react-element-to-jsx-string";
import PropsEditor from "../propsEditor";

import * as DS from "../../lib/components";

/*---------------------------------------------*/

const Playground = (props) => {
	if (props.component) {
		return <ComponentPlayground {...props} />;
	}

	const componentSlug = document.location.pathname.split("/")[2];
	if (componentSlug) {
		return <ComponentPlayground component={componentSlug} />;
	}

	const links = Object.keys(DS).sort().map((key) => (
		<div className="link">
			<DS.Button href={`/playground/${key}`}>{key}</DS.Button>
		</div>
	));

	return (
		<div className="directory">
			<DS.Stack.Spacer />
			<DS.Stack.Spacer />
			<DS.Heading variant="heading4" style={{ textAlign: "center" }}>
				Choose Component
			</DS.Heading>
			<DS.Stack.Spacer />
			<DS.Stack.Spacer />
			{links}
		</div>
	);
};

export default Playground;

/*---------------------------------------------*/

const ComponentPlayground = (props) => {
	const Component = DS[props.component];
	const metadata = Component && Component.METADATA;
	
	const urlParams = new URLSearchParams(document.location.search);
	const queryParams = Object.fromEntries(urlParams);
	Object.keys(queryParams).forEach(key => {
		if (queryParams[key] === 'false') {
			queryParams[key] = false;
		}
	})

	const defaults = {};
	metadata &&
		metadata.props &&
		metadata.props.forEach((prop) => {
			defaults[prop.name] = prop.default;
		});

	const [values, setValues] = useState({...defaults, ...queryParams});
	const [htmlCode, setHtmlCode] = useState("");
	const [canvasBackground, setCanvasBackground] = useState(null);
	const exampleRef = useRef(null);

	useLayoutEffect(() => {
		let code = exampleRef && exampleRef.current && exampleRef.current.innerHTML;
		if (code && code !== htmlCode) {
			setHtmlCode(code);
		}
	},[htmlCode,exampleRef.current?.innerHTML]);

	const onPropsChange = (values) => {
		setValues({ ...values });

		const queryParams = {};
		Object.keys(values).forEach(key => (values[key] !== defaults[key]) && (queryParams[key] = values[key]));

		const url = new URL(window.location);
		url.search = new URLSearchParams(queryParams).toString()
		window.history.pushState({}, '', url);
	};

	if (!metadata) {
		return `Component metadata not defined for ${props.component}`;
  }
	
	let componentProps = {};
	metadata.props && metadata.props.forEach((prop) => {
		let propValue = values[prop.name];
		if (propValue === 'null') {
			propValue = null;
		}

		if (prop.type === 'jsx') {
			propValue = <div dangerouslySetInnerHTML={{__html: propValue}} />;
		}
		
		if (prop.type === 'button') {
			return;
		}

		if (prop.type === 'function') {
			propValue = (...args) => {
				let printableArgs = args.map(arg => (arg && arg.nativeEvent) ? 'Event' : arg);
				DS.Toaster.add({
					title: `${prop.name} was fired`, 
					content: `Args = ${JSON.stringify(printableArgs)}`, 
					duration: 2000, 
				},"playground");
			}; 
		}

		componentProps[prop.name] = propValue;
	});
	
	if (metadata?.demo?.props) {
		Object.keys(metadata.demo.props).forEach(key => {
			componentProps[key] = metadata.demo.props[key];
		});
	}

	let componentExample = metadata.demo?.component || (
		<Component {...componentProps}>
			{metadata?.demo?.content || (metadata.content && metadata.content.default)}
		</Component>
	);
  
	let code = "";
	try {
		code = reactElementToJSXString(componentExample, {
			displayName: (cls) => {
				return cls.type?.METADATA?.name || cls.type;
			},
		});

		code = code.replace("function noRefCheck() {}", "() => {}");
		code = code.replace(/<null dangerouslySetInnerHTML={{__html: '(.*)'}} \/>/g, "$1");
	} catch (e) {
		console.log(e, componentExample);
	}

	let colorOptions = ["#fff", "#fafafa", "#888", "#333"].map((color) => {
		return <div style={{ background: color }} onClick={() => setCanvasBackground(color)} key={color}></div>;
	});

	let tabContent = [
		<div className="playground" key="playground">
			<div className="sidebar lth-u-scroll">
				<DS.Link href="/playground" monochrome>
					<DS.Icon size={16} icon='arrowLeft' style={{marginTop: 4, marginRight: 8}} />
					Back to dashboard
				</DS.Link>
				<DS.Stack.Spacer height="m" />

				<DS.Heading heading="3">{metadata.name}</DS.Heading>
				<DS.Stack.Spacer height="xl" />
				<PropsEditor params={metadata.props} values={values} onChange={onPropsChange} />
			</div>
			<div className="canvas" style={{ backgroundColor: canvasBackground }}>
				<div className="colorOptions">{colorOptions}</div>
				<div className="scroller">
					<div className="example" ref={exampleRef}>
						{componentExample}
					</div>
				</div>
			</div>
			<div className="code">
				<DS.Tabs>
					<DS.Tabs.Tab label="React">
						<DS.CodeSnippet language="jsx">{code}</DS.CodeSnippet>
					</DS.Tabs.Tab>
					<DS.Tabs.Tab label="HTML">
						<DS.CodeSnippet language="html">{htmlCode || ""}</DS.CodeSnippet>
					</DS.Tabs.Tab>
				</DS.Tabs>
			</div>
		</div>,
	];

	return (
		<div>
			<div></div>
			{tabContent}
			<DS.Toaster position='right' id="playground" />
		</div>
	);
};

/*---------------------------------------------*/

// export class Playground extends React.Component {

// 	constructor() {
// 		super();

// 		values = {}
// 		this.exampleRef = React.createRef();
// 	}

// 	render() {
// 		let activeMenuStyle = {fontWeight: 'bold'};

// 		return (
// 			<Router>
// 				<div id="main">
// 					<ul id="menu">
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/button">Button</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/buttongroup">ButtonGroup</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/checkbox">Checkbox</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/dropdown">Dropdown</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/heading">Heading</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/input">Input</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/select">Select</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/stack">Stack</NavLink></li>
// 						<li><NavLink activeStyle={activeMenuStyle} to="/components/tabs">Tabs</NavLink></li>
// 					</ul>
// 					<div id="content">
// 						<Switch>
// 							<Route path="/components/button" key='Button'>
// 								<ComponentDemo component='Button'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/buttongroup" key='ButtonGroup'>
// 								<ComponentDemo component='ButtonGroup'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/checkbox" key='Checkbox'>
// 								<ComponentDemo component='Checkbox'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/dropdown" key='Dropdown'>
// 								<ComponentDemo component='Dropdown'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/heading" key='Heading'>
// 								<ComponentDemo component='Heading'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/input" key='Input'>
// 								<ComponentDemo component='Input'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/select" key='Select'>
// 								<ComponentDemo component='Select'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/stack" key='Stack'>
// 								<ComponentDemo component='Stack'></ComponentDemo>
// 							</Route>
// 							<Route path="/components/tabs" key='Tabs'>
// 								<ComponentDemo component='Tabs'></ComponentDemo>
// 							</Route>
// 						</Switch>
// 					</div>
// 				</div>
// 			</Router>
// 		)
// 	}
// }
