import React from 'react';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-overflowmenu__separator';

/*---------------------------------------------*/

function OverflowMenuSeparator(props) {
	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	return (
		<div className={classes} style={style}></div>
	)
}

/*---------------------------------------------*/

OverflowMenuSeparator.METADATA = {
	name: 'OverflowMenu.Separator'
}

/*---------------------------------------------*/

export default OverflowMenuSeparator;

/*---------------------------------------------*/
