import React, { useEffect, useRef, useState } from 'react';
import { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

/*---------------------------------------------*/

// alignment has the following structure:
// {
//    vertical: 'top' | 'center' | 'bottom',
//    horizontal: 'left' | 'center' | 'right',
//    offset: {
//       x: number,
//       y: number,
//    }
// }
//

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-portal';

/*---------------------------------------------*/

function PortalInner({alignment,...props}, ref) {
	const portalRef = useRef(null);
	const [position,setPosition] = useState({});
	const element = props.element;
	
	useEffect(() => {
		const className = props.reuseId && `${BASE_CLASS}--${props.reuseId}`;
		const div = (className && document.querySelector(className)) || document.createElement('div');

		const style = {
			...props.style,
			display: 'none',
			'z-index': 'var(--zindex-overflow)',
		}
		Object.keys(style).forEach(key => div.style.setProperty(key,style[key]));
		
		if (className) {
			div.className = className;
		}

		document.body.appendChild(div);

		portalRef.current = div;
		setPosition({}); // force update

		return () => {
			portalRef.current.remove();
		};
	}, []);

	useLayoutEffect(() => {
		if (!element || !portalRef.current) {
			return;
		}
		
		updatePortalPosition();
	}, [element,portalRef?.current,JSON.stringify(position)]);

	useEffect(() => {
		if (!element || !portalRef.current) {
			return;
		}

		const observer = createObserver();
		observer.observe(element);

		return () => {
			observer.disconnect();
		}

	}, [element,portalRef?.current,alignment,JSON.stringify(position)]);

	if (!portalRef?.current) {
		return null;
	}

	ref.current = portalRef.current;
	return ReactDOM.createPortal(
		props.children,
		ref.current
	);

	/*------------------------------------------*/

	function onElementPositionChange(e) {
		const rect = element.getBoundingClientRect().toJSON();
		setPosition({...rect});
	}

	/*------------------------------------------*/

	function createObserver() {
		const p = element.getBoundingClientRect().toJSON();
		const margins = {
			top: p.top,
			right: window.innerWidth - p.right,
			bottom: window.innerHeight - p.bottom,
			left: p.left,
		};

		if (margins.top < 0 || margins.bottom < 0) {
			margins.top = 0;
			margins.bottom = 0;
		}

		if (margins.left < 0 || margins.right < 0) {
			margins.left = 0;
			margins.right = 0;
		}

		const marginValues = [margins.top,margins.right,margins.bottom,margins.left];
		const options = {
			root: null,
			rootMargin: marginValues.map(v => `${-1*v}px`).join(' '),
			threshold: [0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.95, 1]
		}
		
		// add an element with a red border with offsets from the edges of the viewport equal to the margins above. If the element already exists - update its position.
		// const marker = document.querySelector(".marker") || document.createElement("div");
		// marker.classList.add("marker");
		// marker.style.position = "fixed";
		// marker.style.inset = marginValues.map(v => `${v}px`).join(' ');
		// marker.style.border = "1px solid red";
		// marker.style.zIndex = "999999";
		// document.body.appendChild(marker);
		
		return new IntersectionObserver(onElementPositionChange, options);
	}

	/*------------------------------------------*/

	function updatePortalPosition() {
		const { vertical, horizontal, offset } = alignment || {};
		const { x = 0, y = 0 } = offset || {};
		const { top, left, width, height } = element.getBoundingClientRect();
		const { width: portalWidth, height: portalHeight } = portalRef.current.getBoundingClientRect();
		
		let topOffset = 0;
		let leftOffset = 0;

		switch (vertical) {
			case 'top':
				topOffset = top - portalHeight - y;
				break;
			case 'center':
				topOffset = top + height / 2 - portalHeight / 2 + y;
				break;
			case 'bottom':
				topOffset = top + height + y;
				break;
			default:
				topOffset = top - portalHeight - y;
				break;
		}

		switch (horizontal) {
			case 'left':
				leftOffset = left - portalWidth - x;
				break;
			case 'center':
				leftOffset = left + width / 2 - portalWidth / 2 + x;
				break;
			case 'right':
				leftOffset = left + width + x;
				break;
			default:
				leftOffset = left - portalWidth - x;
				break;
		}

		// if (topOffset < 0) {
		// 	topOffset = 0;
		// }

		// if (leftOffset < 0) {
		// 	leftOffset = 0;
		// }

		// if (topOffset + portalHeight > windowHeight) {
		// 	topOffset = windowHeight - portalHeight;
		// }

		// if (leftOffset + portalWidth > windowWidth) {
		// 	leftOffset = windowWidth - portalWidth;
		// }

		portalRef.current.style.display = 'block';
		portalRef.current.style.position = 'fixed';
		portalRef.current.style.top = `${topOffset}px`;
		portalRef.current.style.left = `${leftOffset}px`;
	}
}

/*---------------------------------------------*/

export default React.forwardRef(PortalInner);
