import React from "react";
import * as CarbonIcons from "@carbon/icons-react";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-icon';

/*---------------------------------------------*/

export default function Icon({size=24, ...props}) {
	let color = props.color;
	if (color && color.startsWith("--")) {
		color = `var(${color.replace("--", "--color-")})`;
	}

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props,{
		'--lth-icon-size': `${size}px`,
		'--lth-icon-color': color,
		cursor: props.onClick ? "pointer" : "default",
	});

	const iconType = props.icon;
	if (!iconType) {
		return null;
	}

	const key = iconType[0].toUpperCase() + iconType.slice(1) + "24";
	const CarbonIcon = CarbonIcons[key];
	if (!CarbonIcon) {
		return null;
	}

	return (
		<CarbonIcon className={classes} style={style} onClick={props.onClick} />
	);
}

/*---------------------------------------------*/

Icon.METADATA = {
	name: "Icon",
	props: [
		{
			name: "icon",
			type: "string",
			default: "view",
		},
		{
			name: "size",
			type: "number",
		},
		{
			name: "color",
			type: "string",
		},
		{
			name: "onClick",
			type: "function",
		},
		{
			name: "disabled",
			type: "boolean",
		}
	],
};
