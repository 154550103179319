import React from 'react';
import {useRef} from 'react';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';
import useRandomId from 'lib/hooks/useRandomId';

import {Badge,Caption} from '../';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-thumbnails__thumbnail";

/*---------------------------------------------*/

export default function Thumbnail(props) {	
	const ref = useRef(null);
	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);
	const componentId = useRandomId();
		
	const inputProps = {
		type: "radio",
		value: props.value,
		name: props.inputName,
		checked: props.selected,
		disabled: props.disabled,
		onChange: onChange
	}

	const handlers = {
		onMouseEnter: props.onMouseEnter && (() => props.onMouseEnter(props.value)),
		onMouseLeave: props.onMouseLeave && (() => props.onMouseLeave(props.value)),
	}

	let image = props.img || props.children;
	if (typeof image === 'string') {
		image = <img className={`${BASE_CLASS}__control__img ${BASE_CLASS}__control__img--normal`} src={image} alt={props.alt || props.key} />;
	}

	// let selectedImage = props.imgSelected;
	// if (selectedImage === 'string') {
	// 	selectedImage = <img src={selectedImage} alt={props.alt || props.key} />;
	// }

	const badge = props.badge && (
		<div className={`${BASE_CLASS}__badge`}>
			<div className={`${BASE_CLASS}__badge__stripe`}>{props.badge}</div>
		</div>
	)

	return (
		<div className={classes} style={style} {...handlers} key={props.key}>
			<label htmlFor={componentId}>
				<input id={componentId} className={BASE_CLASS+'__input'} {...inputProps} ref={ref} />
				
				<div className={`${BASE_CLASS}__control`}>
					<div className={`${BASE_CLASS}__control__image`}>
						{image}
					</div>

					{ props.label && <Caption size='small'>{props.label}</Caption>}
				</div>
			</label>
			{badge}
		</div>
	)

	/*------------------------------------------*/

	function onChange(e) {
		props.onSelected && props.onSelected(props.value);
	}
}

/*---------------------------------------------*/
