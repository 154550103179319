import React, {useState,useRef} from "react";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';
import { useEffect } from "react";

import { ControlMessage } from "../";

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-checkbox";

/*---------------------------------------------*/

function Checkbox(props) {
	const [value,setValue] = useState(props.value || props.initialValue);
	const ref = useRef(null);

	useEffect(() => {
		setValue(props.value || props.initialValue);
	},[props.initialValue]);

	const customClasses = [
		`${BASE_CLASS}--${props.size || "medium"}`
	];

	props.indeterminate && customClasses.push("lth-is-indeterminate");

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);
	
	const message = props.message && <ControlMessage error={props.error} disabled={props.disabled}>{props.message}</ControlMessage>;

	const label = props.label ?? props.children ?? DEFAULT_LABEL;
	const labelBlock = label === null 
		? null 
		: (
			<div className={`${BASE_CLASS}__label`}>
				<span>{label}</span>
				{message}
			</div>
		);

	const indeterminateMark = props.indeterminate && <div className={`${BASE_CLASS}__indeterminate`} />;

	const currentValue = (typeof props.value !== 'undefined' ? props.value : value); 
	const inputProps = {
		type: "checkbox",
		checked: currentValue || false,
		disabled: props.disabled,
	};

	return (
		<div
			style={style}
			className={classes}
			tabIndex="0"
			onKeyDown={onKeyDown}
		>
			<label>
				<input {...inputProps} onChange={onChange} ref={ref} />
				<div className={`${BASE_CLASS}__container`}>
					<div className={`${BASE_CLASS}__control`}>
						<div className={`${BASE_CLASS}__check`}>
							<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3 6.3L0.5 3.8L1.3 3L3 4.7L6.7 1L7.5 1.8L3 6.3Z" fill="" />
							</svg>
						</div>
						{indeterminateMark}
					</div>
				</div>
				{labelBlock}
			</label>
		</div>
	);

	/*------------------------------------------*/

	function onChange(e) {
		const newValue = e.target && e.target.checked;
		if (typeof props.value === 'undefined') {
			setValue(newValue);
		}

		props.onChange && props.onChange(newValue,props.name);
	}

	function onKeyDown(e) {
		// allow using space to toggle the checkbox value
		if (e.keyCode === " ") {
			e.preventDefault();
			ref.current.click();
		}
	}
}

/*---------------------------------------------*/

const DEFAULT_LABEL = "Label";

/*---------------------------------------------*/

Checkbox.METADATA = {
	name: "Checkbox",
	description:
		"Use to allow merchants to provide text input when the expected input is short. For longer input, use the auto grow or multiline options.",
	props: [
		{
			name: "label",
			type: "string",
			description: "Input label",
		},
		{
			name: "value",
			type: "string",
			options: [
				{
					value: undefined,
					label: "Not set",
				},
				{
					value: true,
					label: "Checked",
				},
				{
					value: false,
					label: "Unchecked",
				},
			],
		},
		{
			name: "initialValue",
			type: "string",
			options: [
				{
					value: undefined,
					label: "Not set",
				},
				{
					value: true,
					label: "Checked",
				},
				{
					value: false,
					label: "Unchecked",
				},
			],
		},
		{
			name: "size",
			type: "string",
			options: [
				{
					value: "medium",
				},
				{
					value: "small",
				},
			],
		},
		{
			name: "indeterminate",
			type: "boolean",
			category: "value",
		},
		{
			name: "disabled",
			type: "boolean",
			description: "Set disabled state",
		},
		{
			name: "error",
			type: "boolean",
			description: "Set error state",
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
			description: "Force hover state.",
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
			description: "Force focus state.",
		},
		{
			name: "message",
			type: "string",
			description: "Message displayed in the component",
		},
		{
			name: "onChange",
			type: "function",
		},
	],
};

/*---------------------------------------------*/

export default Checkbox;