import React from "react";
import ReactDOM from "react-dom";

import Editor from "./views/editor/editor";
import Playground from "./views/playground/playground";
import Pilot from "./pilot/pilot";
import Showcase from './views/themes';

const routes = {
	editor: Editor,
	playground: Playground,
	default: Pilot,
	showcase: Showcase,
};

const page = document.location.pathname.split("/")[1];
document.body.id = page;

const Component = routes[page] || routes.default;

ReactDOM.render(<Component />, document.getElementById("root"));
