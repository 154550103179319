import React from "react";
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-l-grid";

/*---------------------------------------------*/

export default function Grid(props) {
	const customClasses = [];
	['showGrid','fluid','condensed','narrow','nomargin'].forEach(key => {
		props[key] && customClasses.push(`${BASE_CLASS}--${key}`)
	});
	
	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props,{
		'--lth-grid-columns': props.columns,
		'--lth-grid-rows': props.rows,
	});

	const children = React.Children.map(props.children, (child, index) => {
		if (!child || !child.props) {
			return child;
		}

		let childStyle = child.props.style;
		childStyle = {
			...childStyle,
			gridColumnStart: (childStyle && childStyle.gridColumnStart) || child.props["gridStart"] || child.props["grid-start"] || 1,
			gridColumnEnd: (childStyle && childStyle.gridColumnEnd) || child.props["gridEnd"] || child.props["grid-end"] || -1,
		};

		return React.cloneElement(child, {style: childStyle});
	});

	let stub = null;
	if (props.showGrid) {
		stub = [...Array(props.columns || 16)].map((x, index) => (
			<div className="stub" style={{ gridStart: index+1, gridEnd: index+2 }}></div>
		));
	}

	return (
		<div className={classes} style={style}>
			<div className={`${BASE_CLASS}__container`} style={{ alignItems: props.align }}>
				{/* {columns} */}
				{children}
				{stub}
			</div>
		</div>
	);
}

/*---------------------------------------------*/

Grid.Container = (props) => {
	return <div style={props.style}>{props.children}</div>
}

/*---------------------------------------------*/

Grid.METADATA = {
	name: "Grid",
	props: [
		{
			name: "fluid",
			type: "boolean",
		},
		{
			name: "condensed",
			type: "boolean",
		},
		{
			name: "narrow",
			type: "boolean",
		},
		{
			name: "nomargin",
			type: "boolean",
		},
		{
			name: "columns",
			type: "number",
		},
		{
			name: "rows",
			type: "number",
		},
	],
	demo: {
		props: {
			showGrid: true,
		},
	},
};
