import React, {useState,useEffect} from 'react';
import { Select } from '../';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-fontpicker';

/*---------------------------------------------*/

function Fontpicker(props) {
	const [fonts,setFonts] = useState(null);
	const [value,setValue] = useState(null);

	useEffect(() => {
		const apiKey = props.apiKey || 'AIzaSyBefCxz75-gaSRPZNdEaH8YpulE8lwRb_g';
		const url = 'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=' + apiKey;

		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					const weights = ["regular","300","500","100"];
					const fonts = result.items
						.filter(font => !font.family.includes('Icons'))
						.slice(0,20)
						.map(font => {
							const weight = font.variants.reduce((acc, item) => acc || weights.includes(item) && item, null);
							return {
								weight: weight || font.variants[0],
								family: font.family,
							}
						});

					setFonts(fonts);
				},
				(error) => {}
			);
	},[props.apiKey]);

	useEffect(() => {
		if (!props.value || !fonts) {
			return;
		}

		const foundValue = fonts && fonts.find(font => props.value.family === font.family);
		setValue(foundValue);
	},[props.value,fonts]);

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	const fontsMeta = fonts && fonts.map((font,index) => (
		<link href={`https://fonts.googleapis.com/css2?family=${font.family.replace(' ','+')}:wght@${font.weight}&display=swap`} rel="stylesheet" key={index} />
	));

	const options = fonts && fonts.map((font) => (
		<Select.Option value={font} key={font.family}>
			<span style={{fontFamily: font.family}}>{font.family}</span>
		</Select.Option>
	));

	return (
		<div className={classes} style={style}>
			{fontsMeta}
			<Select {...props} value={value}>
				{options || []}
			</Select>
		</div>
	);
}

/*---------------------------------------------*/

Fontpicker.METADATA = {
	name: 'Fontpicker',
	props: [
		{
			name: "label",
			type: "string",
			default: "Choose an option",
		},
		{
			name: "placeholder",
			type: "string",
		},
		{
			name: "onChange",
			type: "function",
			description: "Callback function called when the value was changed",
		},
	],
	demo: {
		// component: (
		// 	<div style={{display: 'flex'}}>
		// 		<Fontpicker />
		// 		&nbsp;
		// 		<Colorpicker />
		// 	</div>
		// )
	}
}

/*---------------------------------------------*/

export default Fontpicker;

/*---------------------------------------------*/