import {Button, Heading, Label, Stack, RangeSlider, Tabs, Text, Thumbnails} from "../../lib/components";
import './theme.scss';

export default function Showcase() {

	// for each color group create a section of divs, each with a different color, to showcase all the colors

	const colors = (
		<div className='colors'>
			<Heading variant="heading3">Primary colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-primary-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Neutral colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-neutral-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Yellow colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-yellow-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Cyan colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-cyan-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Success colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-success-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Warning colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-warning-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Error colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-error-${num})` }}></div>)}
			</div>

			<Heading variant="heading3">Info colors</Heading>
			<div className='colors__section'>
				{[50, 100, 200, 300, 400].map((num) => <div className='colors__color' style={{ backgroundColor: `var(--color-info-${num})` }}></div>)}
			</div>
		</div>
	)

	return (
		<div className='theme'>
			<div className='theme__section'>
				<Heading variant="heading1">Typography</Heading>
				<Stack className='theme__section__content'>
					{[1, 2, 3].map(index => <Heading variant={`heading${index}`}>Heading {index}</Heading>)}
					{['large', 'regular', 'small', 'xsmall'].map((size, index) => <Text size={size}>Body {index + 1}</Text>)}
					{['large', 'regular', 'small'].map((size, index) => <Label size={size}>Label {index + 1}</Label>)}
				</Stack>
			</div>

			<div className='theme__section'>
				<Heading variant="heading1">Buttons</Heading>
				<div className='theme__section__content theme__section__content--buttons'>
					<Stack>
						{['large', 'medium', 'small'].map((size, index) => <div><Button variant='primary' size={size}>Primary Button</Button></div>)}
					</Stack>
					<Stack>
						{['large', 'medium', 'small'].map((size, index) => <div><Button variant='common' size={size}>Secondary Button</Button></div>)}
					</Stack>
				</div>
			</div>

			<div className='theme__section'>
				<Heading variant="heading1">Colors</Heading>
				<div className='theme__section__content'>
					<div className='colors'>
						<Heading variant="heading3">Brand colors</Heading>
						<div className='colors__section'>
							{['blue','black','grey','yellow','green','ash'].map(color => <div className='colors__color' style={{ backgroundColor: `var(--color-brand-${color})` }}></div>)}
						</div>
						<br/>

						<Heading variant="heading3">Light colors</Heading>
						<div className='colors__section'>
							{['blue','grey','yellow','green','white','red'].map(color => <div className='colors__color' style={{ backgroundColor: `var(--color-light-${color})` }}></div>)}
						</div>
					</div>
				</div>
			</div>

			<div className='theme__section'>
				<Heading variant="heading1">Tabs and Thumbnails</Heading>
				<div className='theme__section__content'>
					{/* Should we use the default size and density and update those? */}
					<Tabs xsize='small' xdensity='compact'>
						{['Templates', 'Image', 'Layout', 'Design'].map(tab => <Tabs.Tab id={tab.toLowerCase()} label={tab}>
							<Text>{tab} tab content</Text>
							<br/><br/>

							<Thumbnails size='medium' columns={2}>
								{
									['list','grid','slider','masonry'].map(type => {
										const img = <img src={`https://inffuse-testimonials.appspot.com/v2/settings/img/layouts/${type}.svg`} style={{width: '100%'}} />;
										return <Thumbnails.Item label={type} value={type} img={img} key={type} />
									})
								}
							</Thumbnails>
						</Tabs.Tab>)}
					</Tabs>
				</div>
			</div>

			<div className='theme__section'>
				<Heading variant="heading1">Slider</Heading>
				<div className='theme__section__content'>
					<br/>
					<RangeSlider label='Slider' tooltip='Increase spacing below label?' style={{width: 300}} />
				</div>
			</div>
		</div>
	)
}
