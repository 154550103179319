import { useMemo } from "react";

/*---------------------------------------------*/

function useStyleBuilder(props,customStyle) {
	return useMemo(() => {
		let style = { 
			...props.style,
			...customStyle
		};

		if (props.show === false) {
			style.display = "none";
		}

		return style;

	}, [props.style, props.show, customStyle]);
}

/*---------------------------------------------*/

export default useStyleBuilder;
