import React, { cloneElement } from "react";

import Component from "../component.js";
import { Link, Text } from "../";

/*---------------------------------------------*/

const BREADCRUMB_LINK_COLOR = "var(--breadcrumbs-link-color)";

export default class Breadcrumbs extends Component {
	static BASE_CLASS = "lth-c-breadcrumbs";

	render() {
		this.resetClassNames();
		this.addClass(this.constructor.BASE_CLASS);

		const breadcrumbs = this.props.children.map((child, idx, allChildren) => {
			const modifiedChild = cloneElement(child, {
				isCurrent: idx === allChildren.length - 1,
				size: this.props.size,
			});

			return modifiedChild;
		});

		let separator = (
			<div className={`${this.constructor.BASE_CLASS}__separator`}>
				<Text size={this.props.size}>/</Text>
			</div>
		);

		let placeholders = [...new Array(breadcrumbs.length * 2 - 1)];
		let content = placeholders.map((_obj, index) => (index % 2 === 0 ? breadcrumbs[index / 2] : separator));

		return (
			<div style={this.getStyle()} className={this.getClassNames()}>
				{content}
			</div>
		);
	}
}

/*---------------------------------------------*/

Breadcrumbs.Item = function BreadcrumbItem(props) {
	const { isCurrent, children } = props;

	const LinkOrText = isCurrent ? Text : Link;

	const optionalStyle = {};
	if (!props.disabled && !isCurrent) {
		optionalStyle.color = BREADCRUMB_LINK_COLOR;
	}

	return (
		<LinkOrText {...props} style={optionalStyle}>
			{children}
		</LinkOrText>
	);
};

/*---------------------------------------------*/

Breadcrumbs.METADATA = {
	name: "Breadcrumbs",
	props: [
		{
			name: "crumbs",
			type: "array",
			category: "value",
		},
		{
			name: "size",
			type: "string",
			default: "regular",
			options: [
				{
					value: "small",
					label: "Small",
				},
				{
					value: "regular",
					label: "Regular",
				},
			],
		},
	],
	content: {
		default: [
			<Breadcrumbs.Item href="https://www.site.com">Home</Breadcrumbs.Item>,
			<Breadcrumbs.Item href="https://www.site.com/category">Category</Breadcrumbs.Item>,
			<Breadcrumbs.Item disabled href="https://www.site.com/category">
				Disabled Category
			</Breadcrumbs.Item>,
			<Breadcrumbs.Item href="https://www.site.com/category/page">Current Page</Breadcrumbs.Item>,
		],
	},
};
