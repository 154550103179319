import React,{useState} from 'react';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import {IconButton,Tooltip} from '../';

import { PrismLight as PrismSyntaxHighlighter } from 'react-syntax-highlighter';
import jsx from 'react-syntax-highlighter/dist/cjs/languages/prism/jsx';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-codesnippet';

/*---------------------------------------------*/

export default function CodeSnippet(props) {
	const [tooltip,setTooltip] = useState('Copy');
	let content = props.code || props.children || ' ';
	if (typeof content === 'object' && content.join) {
		content = content.join('\n'); // multi-line support
	}

	const Highlighter = PrismSyntaxHighlighter;
	if (props.language === 'jsx') {
		Highlighter.registerLanguage('jsx', jsx);
	}

	const codeStyle = {
		fontFamily: 'var(--typography-font-family-monospace)',
		fontSize: 'var(--typography-body-regular-size)',
		padding: 'var(--space-inset-m)',
		background: 'transparent'
	}

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	return (
		<div className={classes} style={style}>
			<Highlighter customStyle={codeStyle} language={props.language || 'html'} style={vs}>
				{content}
			</Highlighter>
			<div className={BASE_CLASS+'__copybutton'}>
				<Tooltip content={tooltip} direction={props.tooltipDir} onHide={() => setTooltip('Copy')}>
					<IconButton icon='copy' onClick={() => onCopy(content)} />
				</Tooltip>
			</div>
		</div>
	);
	
	function onCopy(content) {
		navigator.clipboard.writeText(content);
		props.onCopy && props.onCopy(content);

		setTooltip('Copied');
	}
}

/*---------------------------------------------*/

CodeSnippet.METADATA = {
	name: 'CodeSnippet',
	props: [
		{
			name: 'language',
			type: 'string'
		},
		{
			name: 'code',
			type: 'longtext'
		},
		{
			name: "onCopy",
			type: "function",
		}
	],
	demo: {
		// content: '<div class="button">Start</div>'
	}
}