import React,{useState,useEffect,useLayoutEffect, useRef} from 'react';

import OverflowMenuItem from './overflowmenuitem';
import OverflowMenuSeparator from './overflowmenuseparator';

import Portal from 'lib/utils/portal';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-overflowmenu';

/*---------------------------------------------*/

function OverflowMenu({menuAlign='left',disabled,...props}) {
	const [isOpen,setIsOpen] = useState(false);
	const ref = useRef(null);
	const menuRef = useRef(null);
	const portalRef = useRef(null);
	
	useEffect(() => {
		if (!isOpen) {
			return;
		}

		document.addEventListener('mousedown', onClickOutside);
		return () => {
			document.removeEventListener('mousedown', onClickOutside);
		}
	},[isOpen]);

	const panel = props.panel || (
		<div className={`${BASE_CLASS}__scrollable lth-u-scroll`} key={isOpen}>
			{ buildItems(props.children) }
		</div>
	);

	const classes = useClassBuilder(props,BASE_CLASS+'__container');
	const style = useStyleBuilder(props);
	
	const menuClasses = [BASE_CLASS];
	menuClasses.push(`${BASE_CLASS}--${props.size || 'normal'}`);
	menuClasses.push(`lth-is-${menuAlign}-aligned`);
	
	disabled && menuClasses.push('lth-is-disabled');
	isOpen && menuClasses.push('lth-is-open');
	
	return (
		<div className={classes} style={style} onClick={onButtonClick} onKeyDown={onKeyDown} ref={ref}>
			{props.target}
			{ isOpen && (
				<Portal element={ref.current} alignment={{horizontal: menuAlign, vertical: 'bottom'}} reuseId='overflowMenu' ref={portalRef}>
					<div className={menuClasses.join(' ')} onClick={e => e.stopPropagation()} ref={menuRef}>
						{panel}
					</div>
				</Portal>
			)}
		</div>
	);

	/*------------------------------------------*/

	function buildItems(items) {
		return React.Children.map(items,(item,index) => {
			return React.cloneElement(item, {
				index: index, 
				size: props.size, 
				onClick: (index,value) => {
					onItemClick(index,value);
					item.props.onClick && item.props.onClick(index,value);
				},
				key: index
			});
		})
	}

	function onKeyDown(e) {
		if (e.key === " ") {
			onButtonClick(e);
		}
	}

	function onClickOutside(event) {
		if (!clickedInside(ref,event.target) && !clickedInside(portalRef,event.target)) {
			toggleMenu(false);
		}

		function clickedInside(r,el) {
			if (!r.current) {
				return false;
			}

			return r && r.current.contains(el)
		}
	}

	function toggleMenu(value) {
		const newIsOpen = typeof(value) !== 'undefined' ? value : !isOpen;
		setIsOpen(newIsOpen);

		props.onMenuToggle && props.onMenuToggle(newIsOpen);
	}

	function onButtonClick(event) {
		event.stopPropagation();
		toggleMenu();
	}

	function onItemClick(index,value) {
		toggleMenu(false);
		props.onItemClick && props.onItemClick(index,value);
	}
}

/*---------------------------------------------*/

OverflowMenu.Item = OverflowMenuItem;
OverflowMenu.Separator = OverflowMenuSeparator;

OverflowMenu.METADATA = {
	name: 'OverflowMenu',
	description: '',
	props: [
		{
			name: 'target',
			type: 'jsx',
			default: '<div style="font-size: 16px">Click Me</div>'
		},
		{
			name: 'size',
			type: 'string',
			default: 'normal',
			options: [
				{
					value: 'slim'
				},
				{
					value: 'normal'
				}
			]
		},
		{
			name: 'menuAlign',
			type: 'string',
			default: 'left',
			options: [
				{
					value: 'left'
				},
				{
					value: 'right'
				}
			]
		},
		{
			name: 'onItemClick',
			type: 'function',
			description: 'Callback function called when an item was clicked'
		},
	],
	content: {
		default: [
			<OverflowMenu.Item label="Option 1" value="value1" />,
			<OverflowMenu.Item label="Option 2" value="value2" />,
			<OverflowMenu.Item value="value2"><div style={{padding: 24, color: 'red'}}>Custom content</div></OverflowMenu.Item>,
			<OverflowMenu.Separator />,
			<OverflowMenu.Item icon='settings' label="Option 3" value="value3" />,
			...[...new Array(30)].map((_,i) => <OverflowMenu.Item label={`Option ${i+4}`} value={`value${i+4}`} selected={i===25} />)
		]
	}
};


/*---------------------------------------------*/

export default OverflowMenu;

/*---------------------------------------------*/
