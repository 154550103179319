/* CHECK DISABLED BUG */

import React,{useState} from "react";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import Icon from "../icon";
import Spinner from "../spinner";

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-button";

/*---------------------------------------------*/

function Button({variant='common', size='medium', iconSize=20, ...props}) {
	const [isLoading,setIsLoading] = useState(false);

	const customClasses = [
		`${BASE_CLASS}--${variant}`,
		`${BASE_CLASS}--${size}`,
	];

	props.spaced && customClasses.push("lth-is-spaced");
	props.monochrome && customClasses.push("lth-is-monochrome");

	const customStyle = {};
	props.color && (customStyle.color = props.color);

	if (props.ingroup) {
		customClasses.push("lth-is-ingroup");
		if (props.position === "first") customClasses.push("lth-is-groupFirst");
		else if (props.position === "last") customClasses.push("lth-is-groupLast");
		else customClasses.push("lth-is-groupInner");
	}

	const platform = props.platform;
	if (platform) {
		customClasses.push(`platform-${platform}`);
		customClasses.push(`variant-${variant}`);
	}

	let iconLeft = <div></div>; // grid cell filler
	if (props.iconLeft) {
		iconLeft = <Icon icon={props.iconLeft} className={BASE_CLASS + "__icon"} size={iconSize} />;
		customClasses.push("lth-has-icon");
	}

	let iconRight = <div></div>; // grid cell filler
	if (props.iconRight || props.icon) {
		iconRight = (
			<Icon
				icon={props.iconRight || props.icon}
				className={BASE_CLASS + "__icon"}
				size={iconSize}
			/>
		);

		customClasses.push("lth-has-icon");
	}

	let ButtonTag = props.href ? "a" : "button";
	let label = <div></div>;

	let buttonLabel = (props.label === null) ? null : props.children || props.label || props.text || "Button label";

	if (buttonLabel) {
		label = <div className={BASE_CLASS + "__label"}>{buttonLabel}</div>;
	} else {
		customClasses.push("lth-is-icononly");
	}

	const classes = useClassBuilder({...props, loading: props.loading || isLoading},BASE_CLASS,customClasses);
	const style = useStyleBuilder(props,customStyle);
	
	const attrWhitelist = ['id','type','href','target'];
	const htmlProps = Object.fromEntries(attrWhitelist.map(key => [key,props[key]]).filter(pair => typeof pair[1] !== 'undefined'));
	
	const dataProps = {};
	Object.keys(props).filter(key => key.startsWith('data-')).forEach(key => (dataProps[key] = props[key]));

	const buttonProps = {
		...htmlProps,
		...dataProps,
		className: classes,
		style: style,
		tabIndex: props.disabled ? -1 : 0,
		onClick: (e) => {
			if (!props.onClick) {
				return;
			}
			
			const result = props.onClick(e);
			if (typeof result === 'object' && result.then) {
				setIsLoading(true);
				result.then(() => setIsLoading(false));
			}
		},
	};

	const spinner = (props.loading || isLoading) ? <Spinner /> : null;

	return (
		<ButtonTag {...buttonProps}>
			{iconLeft}
			{label}
			{iconRight}
			{spinner}
		</ButtonTag>
	);
}

/*---------------------------------------------*/

Button.METADATA = {
	name: "Button",
	description: 'Buttons are used primarily for actions, such as "Add", "Close", "Cancel", or "Save". Plain buttons, which look similar to links, are used for less important or less commonly used actions, such as "view shipping settings".',
	props: [
		{
			name: "variant",
			type: "string",
			default: "common",
			description: "Choose which variant of button to display",
			options: [
				{
					value: "primary",
					description: "For the principal call to action on the page.",
				},
				{
					value: "common",
					description: "For secondary actions on each page.",
				},
				{
					value: "flat",
					description: "When an action does not require primary dominance on the page.",
				},
				{
					value: "inverse",
					description: "",
				},
				{
					value: "outline",
					description: "When an action does not require primary dominance on the page.",
				},
				{
					value: "danger",
					description: "",
				},
				{
					value: "warning",
					description: "",
				},
			],
		},
		{
			name: "type",
			type: "string",
			options: [
				{
					value: "submit",
				},
				{
					value: "reset",
				},
				{
					value: "button",
				},
			],
		},
		{
			name: "size",
			type: "string",
			default: "medium",
			options: [
				{
					value: "medium",
					description: "medium sized button.",
				},
				{
					value: "large",
					description: "large sized button.",
				},
				{
					value: "small",
					description: "small sized button.",
				},
			],
		},
		{
			name: "loading",
			type: "boolean",
			default: false,
		},
		{
			name: "fullwidth",
			type: "boolean",
			default: false,
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
			description: "Show the component in disabled state.",
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
			description: "Force hover state.",
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
			description: "Force focus state.",
		},
		{
			name: "label",
			type: "string",
			description: "Label displayed in the button",
			default: "Button Label",
		},
		{
			name: "color",
			type: "string",
			description: "The color of the button",
		},
		{
			name: "iconLeft",
			type: "string",
			description: "Icon displayed on the left side of the button",
		},
		{
			name: "iconRight",
			type: "string",
			description: "Icon displayed on the right side of the button",
		},
		{
			name: "iconSize",
			type: "number",
		},
		{
			name: "href",
			type: "string",
			description: "Button link",
		},
		{
			name: "target",
			type: "string",
			options: [
				{
					value: "_self",
					label: "Current tab",
				},
				{
					value: "_blank",
					label: "New tab",
				},
				{
					value: "_parent",
					label: "Parent window",
				},
				{
					value: "_top",
					label: "Top window",
				},
			],
		},
		{
			name: "onClick",
			type: "function",
			description: "Callback function called after the button is clicked",
		},
	],
};

/*---------------------------------------------*/

export default Button;

/*---------------------------------------------*/
