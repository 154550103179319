import { useMemo } from "react";

/*---------------------------------------------*/

function useRandomBuilder(props,customStyle) {
	return useMemo(() => {
		return Math.floor(Math.random() * 10000);

	}, []);
}

/*---------------------------------------------*/

export default useRandomBuilder;
