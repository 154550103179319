import React,{useState} from 'react';
import Button from '../button';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-buttongroup';

export default function ButtonGroup(props) {
	const [uncontrolledValue, setUncontrolledValue] = useState(props.value);
	const value = props.value !== undefined ? props.value : uncontrolledValue;

	const buttons = React.Children.map(props.children,(child,iChild) => {
		const position = {
			0: 'first',
			[props.children.length-1]: 'last',
		}[iChild] || 'inner';

		const childValue = child.props.value !== undefined ? child.props.value : iChild;
		const childProps = {
			ingroup: true, 
			position: position,
			onClick: (e) => {
				setUncontrolledValue(childValue);
				props.onChange?.apply(null,[childValue]);
			}
		};

		if (childValue === value) {
			childProps.pressed = true;
		}

		return React.cloneElement(child, childProps);
	});

	const classes = useClassBuilder(props,BASE_CLASS);
	const styles = useStyleBuilder(props);

	return (
		<div className={classes} style={styles}>
			{buttons}
		</div>
	);

	/*------------------------------------------*/
}

/*---------------------------------------------*/

ButtonGroup.METADATA = {
	name: 'ButtonGroup',
	description: 'Todo',
	props: [
		{
			name: 'type',
			type: 'string',
			description: 'Choose which type of tabs component to display',
			options: [
				{
					value: 'basic',
					description: 'basic'
				},
				{
					value: 'minimal',
					description: 'minimal'
				}
			]
		},
		{
			name: 'value',
			type: 'string',
		},
		{
			name: 'onChange',
			type: 'function',
		}
	],
	demo: {
		content: [
			<Button value='first' key={1}>Option 1</Button>,
			<Button value='second' key={2}>Option 2</Button>,
			<Button key={3}>Option 3</Button>
		]			
	}
}
	
/*---------------------------------------------*/