import React from "react";
import Component from "../component.js";
import { Button } from "../";

/*---------------------------------------------*/

let GoogleIcon = (props) => (
	<div style={props.style}>
		{props.disabled ? (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0)">
					<path
						d="M8.99996 3.5625C10.3275 3.5625 11.5162 4.02 12.4537 4.9125L15.0225 2.34375C13.4625 0.8925 11.4262 0 8.99996 0C5.48246 0 2.44121 2.0175 0.959961 4.9575L3.95246 7.27875C4.66121 5.145 6.65246 3.5625 8.99996 3.5625Z"
						fill="#C0C5D1"
					/>
					<path
						d="M17.6175 9.20625C17.6175 8.6175 17.5612 8.0475 17.475 7.5H9V10.8825H13.8525C13.635 11.9925 13.005 12.9375 12.06 13.575L14.9588 15.825C16.65 14.2575 17.6175 11.94 17.6175 9.20625Z"
						fill="#C0C5D1"
					/>
					<path
						d="M3.94875 10.7213C3.76875 10.1775 3.66375 9.60002 3.66375 9.00002C3.66375 8.40002 3.765 7.82252 3.94875 7.27877L0.95625 4.95752C0.345 6.17252 0 7.54502 0 9.00002C0 10.455 0.345 11.8275 0.96 13.0425L3.94875 10.7213Z"
						fill="#C0C5D1"
					/>
					<path
						d="M9.00005 18C11.43 18 13.4738 17.2013 14.9588 15.8213L12.06 13.5713C11.2538 14.115 10.215 14.4338 9.00005 14.4338C6.65255 14.4338 4.6613 12.8513 3.9488 10.7175L0.956299 13.0388C2.4413 15.9825 5.48255 18 9.00005 18Z"
						fill="#C0C5D1"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="18" height="18" fill="white" />
					</clipPath>
				</defs>
			</svg>
		) : (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0)">
					<path
						d="M8.99999 3.5625C10.3275 3.5625 11.5162 4.02 12.4537 4.9125L15.0225 2.34375C13.4625 0.8925 11.4262 0 8.99999 0C5.48249 0 2.44124 2.0175 0.959991 4.9575L3.95249 7.27875C4.66124 5.145 6.65249 3.5625 8.99999 3.5625Z"
						fill="#EA4335"
					/>
					<path
						d="M17.6175 9.20625C17.6175 8.6175 17.5612 8.0475 17.475 7.5H9V10.8825H13.8525C13.635 11.9925 13.005 12.9375 12.06 13.575L14.9588 15.825C16.65 14.2575 17.6175 11.94 17.6175 9.20625Z"
						fill="#4285F4"
					/>
					<path
						d="M3.94875 10.7213C3.76875 10.1775 3.66375 9.60002 3.66375 9.00002C3.66375 8.40002 3.765 7.82252 3.94875 7.27877L0.95625 4.95752C0.345 6.17252 0 7.54502 0 9.00002C0 10.455 0.345 11.8275 0.96 13.0425L3.94875 10.7213Z"
						fill="#FBBC05"
					/>
					<path
						d="M9 18C11.43 18 13.4738 17.2013 14.9588 15.8213L12.06 13.5713C11.2538 14.115 10.215 14.4338 9 14.4338C6.6525 14.4338 4.66125 12.8513 3.94875 10.7175L0.956253 13.0388C2.44125 15.9825 5.4825 18 9 18Z"
						fill="#34A853"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="18" height="18" fill="white" />
					</clipPath>
				</defs>
			</svg>
		)}
	</div>
);

/*---------------------------------------------*/

export default class SocialButton extends Component {
	static BASE_CLASS = "lth-c-socialbutton";

	constructor() {
		super();

		this.state = {};

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {}

	render() {
		this.resetClassNames();
		this.addClass(this.constructor.BASE_CLASS);

		let style = {
			googleButton: {
				color: this.props.disabled ? "var(--color-neutral-400)" : "var(--color-neutral-700)",
			},
			googleButtonIcon: {
				display: "inline-block",
				lineHeight: 0,
				marginTop: 3,
				marginRight: 11,
				verticalAlign: "top",
			},
		};

		let props = {
			...this.props,
			variant: "flat",
			style: style.googleButton,
		};

		return (
			<div className={this.getClassNames()} style={this.getStyle()}>
				<Button {...props}>
					<div
						style={{
							paddingLeft: "var(--space-inline-s)",
							paddingRight: "var(--space-inline-s)",
						}}
					>
						<GoogleIcon disabled={this.props.disabled} style={style.googleButtonIcon} />
						{this.props.children || this.props.text || "Connect to Google"}
					</div>
				</Button>
			</div>
		);
	}
}

/*---------------------------------------------*/

SocialButton.METADATA = {
	name: "Socialbutton",
	props: [
		// {
		// 	name: 'size',
		// 	type: 'string',
		// 	default: 'regular',
		// 	description: 'Choose the size of button',
		// 	options: [
		// 		{
		// 			value: 'slim',
		// 			description: 'Slim sized button.'
		// 		},
		// 		{
		// 			value: 'regular',
		// 			description: 'Regular sized button.'
		// 		},
		// 		{
		// 			value: 'super',
		// 			description: 'Super-sized button.'
		// 		}
		// 	]
		// },
		{
			name: "disabled",
			type: "boolean",
			default: false,
			description: "Show the component in disabled state.",
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
			description: "Force hover state.",
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
			description: "Force focus state.",
		},
		{
			name: "onClick",
			type: "function",
			description: "Callback function called after the button is clicked",
		},
	],
};
