import React from 'react';
import {useEffect,useState,useRef} from 'react';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

import Toast from './toast';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-toaster";
const MSG_ADD = "toaster::add";
const TOAST_DURATION = 5000;

/*---------------------------------------------*/

function Toaster(props) {

	const [items,setItems] = useState([]);
	const itemsRef = useRef({});

	useEffect(() => {
		window.addEventListener("message",onMessage);

		return () => window.removeEventListener("message",onMessage);
	},[]);

	const customClasses = [];
	if (props.position) {
		customClasses.push(BASE_CLASS+"--position-"+props.position)
	}

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);
	
	const content = items
		.sort((a,b) => a.timestamp - b.timestamp)
		.map((item,_index) => (
			<div className={BASE_CLASS+'__item animate-in'} key={item.id} ref={el => itemsRef.current[item.id] = el}>
				<Toast
					id={item.id} 
					title={item.title} 
					content={item.content} 
					icon={item.icon} 
					variant={item.type}
					onClose={() => removeItem(item.id)}
				/>
			</div>
		));

	return (
		<div className={classes} style={style}>
			{content}
		</div>
	)
	
	/*------------------------------------------*/

	function onMessage(msg) {
		const event = msg.data;
		if (event.type !== MSG_ADD) {
			return;
		}

		// if both are undefined - that's fine
		if (event.target !== props.id) {
			return;
		}

		const item = event.item;
		addItem(item);
	}

	function addItem(item) {
		item.id = item.id || Math.random().toString().slice(2);
		item.timestamp = 1*new Date();
		
		setItems(items => [...items,item]);
		
		((itemId) => {
			setTimeout(() => removeItem(itemId), item.duration || TOAST_DURATION);
		})(item.id);
	}
	
	function removeItem(id) {
		if (!itemsRef.current[id]) {
			return;
		}

		itemsRef.current[id].classList.remove("animate-in");
		itemsRef.current[id].classList.add("animate-out");
		setTimeout(() => {
			setItems(items => items.filter(item => item.id !== id));
			delete itemsRef.current[id];
		},1000);
	}
}

/*---------------------------------------------*/

Toaster.add = (item,target) => {
	window.postMessage({
		type: MSG_ADD,
		target: target,
		item: item
	});
}

/*---------------------------------------------*/

export default Toaster;

/*---------------------------------------------*/

Toaster.METADATA = {
	name: 'Toaster',
	props: [
		{
			name: "Add Toast",
			type: "button",
			action: () => Toaster.add("Test")
		}
	],
	content: {
	}
}