import React, {useState} from "react";

import { Icon } from "../";
import OverflowMenu from "../overflowmenu/index.js";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-dropdown";

/*---------------------------------------------*/

export default function Dropdown({size='normal',label='Open Menu',...props}) {
	const [isMenuOpen,setIsMenuOpen] = useState(false);

	const customClasses = [`${BASE_CLASS}--${size}`];
	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	const iconType = isMenuOpen ? "ChevronUp" : "ChevronDown";
	const button = (
		<div className={`${BASE_CLASS}__button`}>
			<span className={`${BASE_CLASS}__button__label`}>{label}</span>
			<Icon
				className={`${BASE_CLASS}__icon`}
				size={20}
				icon={iconType}
				color="var(--dropdown-button-arrow-color)"
			/>
		</div>
	);

	const items = React.Children.map(props.children, (item, _index) => {
		return React.cloneElement(item, {onClick: item.props.onClick || props.onItemClick});
	});

	return (
		<div className={classes} style={style} tabIndex={0}>
			<OverflowMenu onMenuToggle={onMenuToggle} target={button}>
				{items}
			</OverflowMenu>
		</div>
	);

	/*------------------------------------------*/
	
	function onMenuToggle(isOpen) {
		setIsMenuOpen(isOpen);
	}
	
	/*------------------------------------------*/
}

/*---------------------------------------------*/

Dropdown.Option = (props) => <OverflowMenu.Item {...props} />;
Dropdown.Option.METADATA = {
	...OverflowMenu.Item.METADATA,
	name: "Dropdown.Option",
};

Dropdown.Separator = (props) => <OverflowMenu.Separator {...props} />;
Dropdown.Separator.METADATA = {
	...OverflowMenu.Separator.METADATA,
	name: "Dropdown.Separator",
};

/*---------------------------------------------*/

Dropdown.METADATA = {
	name: "Dropdown",
	description: "",
	props: [
		{
			name: "label",
			type: "string",
		},
		{
			name: "options",
			type: "string",
			description: "Options to select from",
			category: "value",
		},
		{
			name: "size",
			type: "string",
			default: "normal",
			options: [
				{
					value: "slim",
				},
				{
					value: "normal",
				},
			],
		},
		{
			name: "onItemClick",
			type: "function",
			description: "Callback function called when an item was clicked",
			category: "value",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
	],
	content: {
		default: [
			<Dropdown.Option icon='add' value="value1">Option 1</Dropdown.Option>,
			<Dropdown.Option icon='add' value="value2">Option 2</Dropdown.Option>,
			<Dropdown.Option icon='add' value="value3">Option 3</Dropdown.Option>,
		],
	},
};
