import React,{useRef,useState} from 'react';
import {Icon,Label} from '../';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';
import {useKeyboardFocus} from 'lib/hooks/focusManager';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-rating';
const MAX_VALUE = 5;

/*---------------------------------------------*/

export default function Rating(props) {
	const ref = useRef(null);
	const [hoverIndex,setHoverIndex] = useState(0);
	const [value,setValue] = useState(0);
	const [keyboardFocus, _setKeyboardFocus] = useKeyboardFocus(ref);
	
	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	const currentValue = props.value || value;
	const stars = [...Array(MAX_VALUE)].map((v,index) => {
		const starClassNames = [`${BASE_CLASS}__star`];

		let color = 'var(--rating-star-regular-color)';	
		if (props.disabled) {
			color = 'var(--rating-star-disabled-color)';

		} else {
			if (hoverIndex > 0) {
				if (index < hoverIndex) {
					color = 'var(--rating-star-hover-color)';
				}

				if (index === hoverIndex-1) {
					starClassNames.push('lth-is-focus-target');
				}
				
			} else {
				if (currentValue > index) {
					color = 'var(--rating-star-selected-color)';
				}

				if (index === currentValue-1 || (!currentValue && !index)) {
					starClassNames.push('lth-is-focus-target');
				}
			}
		}

		const starProps = {
			className: starClassNames.join(' '),
			onMouseEnter: () => onStarMouseEnter(index+1),
			onMouseDown: () => onStarMouseDown(index+1),
			key: index,
		}

		return (
			<div {...starProps}>
				<Icon icon='starFilled' color={color} size={20} index={index} />
			</div>
		)
	});

	const starsProps = {
		className: `${BASE_CLASS}__stars ${keyboardFocus ? 'lth-has-keyboardfocus' : ''}`,
		onMouseLeave: onMouseLeave,
		onKeyDown: onKeyDown,
		onFocus: onFocus,
		onBlur: onBlur,
		tabIndex: 0,
	}

	return (
		<div className={classes} style={style}>
			<Label disabled={props.disabled} tooltip={props.tooltip}>{props.label}</Label>
			<div {...starsProps} ref={ref}>
				{stars}
			</div>
		</div>
	)

	/*----------------------------------------------------*/

	function updateValue(value) {
		setValue(value);
		setHoverIndex(value);

		props.onChange(value,props.name);
	}

	function onMouseLeave() {
		setHoverIndex(0);
	}

	function onStarMouseEnter(index) {
		setHoverIndex(index);
	}

	function onFocus(e) {
	}

	function onBlur() {
		setHoverIndex(0);
	}

	function onStarMouseDown(index) {
		updateValue(index);
	}
	
	function onKeyDown(event) {
		switch (event.key) {
			case 'ArrowRight': {
				// setting initial value inside onFocus doesn't cover the case where
				// a star is clicked, mouse leaves (resettings the hoverIndex) and then arrows are used
				const index = hoverIndex || value || 1;
				if (index < MAX_VALUE) {
					setHoverIndex(index+1);
				}
				break;
			}

			case 'ArrowLeft': {
				const index = hoverIndex || value || 1;
				if (index > 1) {
					setHoverIndex(index-1);
				}				
				break;
			}

			case "Enter": {
				if (hoverIndex) {
					updateValue(hoverIndex);
				}
				break;
			}

			default:
				break;
		}
	}
}

/*-------------------------------------------------------*/

Rating.METADATA = {
	name: 'Rating',
	props: [
		{
			name: 'label',
			type: 'string',
			default: 'Rating label',
			description: 'Label'
		},
		{
			name: 'value',
			type: 'number',
			options: [
				{
					value: 1
				},
				{
					value: 2
				},
				{
					value: 3
				},
				{
					value: 4
				},
				{
					value: 5
				}
			]
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: 'disabled',
			type: 'boolean',
			default: false
		},
		{
			name: 'onChange',
			type: 'function',
			description: 'Callback function called after the value is changed'
		}
	]
}
