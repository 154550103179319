import React from "react";
import { Icon, Text } from "../../../../lib/components";
import S from "./MenuItem.module.scss";

export default function MenuItem({ title, icon, selected }) {
	const classNames = `${S.container} ${selected ? S.selected : ""}`;

	return (
		<div className={classNames}>
			<Icon size="16px" icon={icon} />
			<Text size="small">{title}</Text>
		</div>
	);
}
