import React from "react";

import Component from "../component.js";
import { Label } from "../";
import Datepicker from "../datepicker";

/*---------------------------------------------*/

export default class DateRangePicker extends Component {
	static BASE_CLASS = "lth-c-daterangepicker";

	constructor() {
		super();

		this.state = {
			start: {
				date: null,
				time: null,
			},
			end: {
				date: null,
				time: null,
			},
		};

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {}

	render() {
		this.resetClassNames();
		this.addClass(this.constructor.BASE_CLASS);

		let children = this.props.children || [];
		children.length < 1 && children.push(<Datepicker dateLabel="Start date" timeLabel="Time" />);
		children.length < 2 && children.push(<Datepicker dateLabel="End date" timeLabel="Time" />);

		let [startDatePicker, endDatePicker] = React.Children.map(children, (picker, index) => {
			let props = {
				onChange: (date, time) => this.setState({ [index ? "end" : "start"]: { date: date, time: time } }),
			};

			if (index === 1) {
				props.minDate = this.state.start.date;
			}

			return React.cloneElement(picker, props);
		});

		let label = this.props.label && <Label>{this.props.label}</Label>;

		return (
			<div style={this.getStyle()} className={this.getClassNames()} tabIndex={0}>
				{label}
				<div className={this.constructor.BASE_CLASS + "__container"}>
					{startDatePicker}
					<div className={this.constructor.BASE_CLASS + "__separator"}>&ndash;</div>
					{endDatePicker}
				</div>
			</div>
		);
	}
}

/*---------------------------------------------*/

DateRangePicker.METADATA = {
	name: "Daterangepicker",
	props: [
		{
			name: "label",
			type: "string",
			default: "Enter your input",
		},
	],
};
