import React from 'react';
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-heading';

/*---------------------------------------------*/

export default function Heading({variant='heading3',...props}) {
	const customClasses = [];
	customClasses.push(`${BASE_CLASS}--${variant}`);

	const customStyle = {};
	if (props.color?.startsWith("--")) {
		customStyle.color = `var(${props.color.replace("--", "--color-")})`;
	}

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props,customStyle);

	return (
		<div className={classes} style={style}>
			{props.children}
		</div>
	);
}

/*---------------------------------------------*/

Heading.METADATA = {
	name: 'Heading',
	description: 'Headings are used...',
	props: [
		{
			name: 'variant',
			type: 'string',
			default: 'heading1',
			description: 'Choose which variant of heading to display',
			options: [
				{
					value: 'heading1',
					description: 'Heading level 1'
				},
				{
					value: 'heading2',
					description: 'Heading level 2'
				},
				{
					value: 'heading3',
					description: 'Heading level 3'
				},
				{
					value: 'heading4',
					description: 'Heading level 4'
				},
				{
					value: 'heading5',
					description: 'Heading level 5'
				},
				{
					value: 'heading6',
					description: 'Heading level 6'
				},
			]
		},
		{
			name: "color",
			type: "string",
		},
	],
	content: {
		type: 'string',
		default: 'Heading'
	}
}