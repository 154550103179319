import React, { useState } from "react";
import S from "./AddNewEvent.module.scss";
import {
	Checkbox,
	DateRangePicker,
	Input,
	RadioButton,
	RadioButtonGroup,
	RangeSlider,
} from "../../../../../lib/components";
import SettingsSection from "../../SettingsSection/SettingsSection";

export function GeneralInfoSection() {
	return (
		<SettingsSection title="General Info">
			<Input label="Label" inputWidth />
			<Input label="Label" inputWidth type="textarea" />
		</SettingsSection>
	);
}

export function DateAndTimeSection() {
	return (
		<SettingsSection title="Date and Time">
			<DateRangePicker label="Choose date and time:" />
			<Checkbox label="All day" />
		</SettingsSection>
	);
}

export function LocationSection() {
	return (
		<SettingsSection title="Location">
			<RadioButtonGroup label="Where is the event:">
				<RadioButton label="Physical Event" value="physical" />
				<RadioButton label="Online Event" value="online" />
			</RadioButtonGroup>
			<Input label="Link:" inputWidth />
		</SettingsSection>
	);
}

const radios = ["idle", "checked", "error"];
export function AboutYourEventSection() {
	const [rangeValue, setRangeValue] = useState(0);

	return (
		<SettingsSection title="About Your Event">
			<Input label="Event summary:" type="textarea" inputWidth />
			<Input label="Label" type="number" inputWidth />
			<div className={S.rangeContainer}>
				<RangeSlider label="Label" onChange={setRangeValue} />
				<span>{rangeValue}</span>
			</div>
			<RadioButtonGroup>
				{radios.map((state) => {
					const stateProp = {};
					stateProp[state] = true;
					if (state === "error") {
						stateProp.message = "error message";
					}
					return <RadioButton label={state} value={state} key={state} {...stateProp} />;
				})}
			</RadioButtonGroup>
		</SettingsSection>
	);
}
