import React from "react";
import { Divider, Text } from "../../../../lib/components";
import MenuItem from "../MenuItem/MenuItem";
import S from "./MenuOptions.module.scss";

export default function MenuOptions() {
	return (
		<div className={S.container}>
			<div className={S.upper}>
				<MenuItem title="Settings" icon="settings" selected />
				<MenuItem title="Templates" icon="template" />
				<MenuItem title="Content" icon="document" />
				<MenuItem title="Install" icon="code" />

				<Divider />

				<div className={S.title}>
					<Text color="--neutral-500" size="small">
						NEED HELP?
					</Text>
				</div>

				<MenuItem title="Support" />
			</div>

			<div className={S.lower}>
				<MenuItem title="Back to dashboard link" icon="arrowLeft" />
			</div>
		</div>
	);
}
