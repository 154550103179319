import React,{ useEffect, useRef, useState } from "react";
import { Input, Label, Text } from "../";

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-rangeslider";
const HANDLE_SIZE = 12;

/*---------------------------------------------*/

export default function RangeSlider(props) {
	const ref = useRef(null);
	const [value,setValue] = useState(null);
	const [isDragging,setIsDragging] = useState(false);

	useEffect(() => {
		if (isDragging) {
			document.addEventListener("mousemove", onDragging);
			document.addEventListener("touchmove", onDragging);
	
			document.addEventListener("mouseup", onDragEnd);
			document.addEventListener("touchend", onDragEnd);
		}

		return () => {
			if (isDragging) {
				document.removeEventListener("mousemove", onDragging);
				document.removeEventListener("touchmove", onDragging);
	
				document.removeEventListener("mouseup", onDragEnd);
				document.removeEventListener("touchend", onDragEnd);
			}
		}
	},[isDragging])

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	const min = 1*props.min || 0;
	const max = 1*props.max || 100;

	let currentValue = (props.value ?? value) || 0;
	currentValue = Math.min(currentValue, max);
	currentValue = Math.max(currentValue, min);

	const trackPadding = HANDLE_SIZE / 2; // half of the handle size
	const percent = (100 * (currentValue - min)) / (max - min);
	const containerWidth = `calc(${trackPadding}px + (100% - ${trackPadding * 2}px) * ${percent} / 100)`;

	const styles = {
		container: {
			width: containerWidth,
		},
	};

	const label = props.label && <Label disabled={props.disabled} tooltip={props.tooltip}>{props.label}</Label>;

	return (
		<div
			style={style}
			className={classes}
			onKeyDown={onKeyDown}
			tabIndex={props.disabled ? false : 0}
		>
			{label}
			<div className={BASE_CLASS+"__control"}>
				<div className={BASE_CLASS+"__track"} onMouseDown={onTrackClick} onTouchStart={onTrackClick} ref={ref}>
					<div className={BASE_CLASS+"__fill-container"} style={styles.container}>
						<div className={BASE_CLASS+"__fill"}></div>
						<div className={BASE_CLASS+"__handle"} onMouseDown={onDragStart} onTouchStart={onDragStart}></div>
					</div>
				</div>
				{getValueElement(min,max,currentValue)}
			</div>
		</div>
	);

	/*------------------------------------------*/

	function getValueElement(min,max,currentValue) {
		if (props.valueType === "number") {
			return (
				<div className={BASE_CLASS+"__value--number-input"}>
					<Input
						type="number"
						value={currentValue}
						disabled={props.disabled}
						size="slim"
						min={min}
						max={max}
						onChange={onChange}
					/>
				</div>
			);
		}
				
		if (props.valueType === "text") {
			return (
				<div className={BASE_CLASS+"__value--text-input"}>
					<Input value={currentValue} size="slim" disabled={props.disabled} onChange={onChange} />
				</div>
			);
		}
	
		if (props.valueType === "plain") {
			return (
				<div className={BASE_CLASS+"__value--text"}>
					<Text disabled={props.disabled}>{currentValue}</Text>
				</div>
			);
		}

		return null;
	}

	function onChange(value) {
		setValue(value);

		props.onChange && props.onChange(value,props.name);
	}

	function onDragEnd(e) {
		setIsDragging(false);
		props.onSlideEnd && props.onSlideEnd(props.name);
	}

	function onDragStart(e) {
		if (e.button !== 0) {
			return;
		}

		setIsDragging(true);
	}

	function onTrackClick(e) {
		setIsDragging(true);
		onPositionChange(e.pageX || e.touches[0].pageX);
	}

	function onPositionChange(offset) {
		const totalWidth = ref.current.offsetWidth;
		const x = offset - ref.current.getBoundingClientRect().left;
		const min = 1 * props.min || 0;
		const max = 1 * props.max || 100;

		let value = min + (max - min) * (x / totalWidth);
		value = Math.min(value, max);
		value = Math.max(value, min);

		if (!props.floatingNumber) {
			value = Math.round(value);
		}

		onChange(value);
	}

	function onDragging(e) {
		if (!isDragging) {
			return;
		}
		
		onPositionChange(e.pageX || e.touches[0].pageX);

		e.stopPropagation();
		e.preventDefault();
	}

	function onKeyDown(event) {
		let newValue = (props.value ?? value) || 0;

		switch (event.key) {
			case "ArrowRight": {
				newValue += 1;
				break;
			}

			case "ArrowLeft": {
				newValue -= 1;
				break;
			}

			default:
				break;
		}

		if (newValue !== value) {
			let min = 1 * props.min || 0;
			let max = 1 * props.max || 100;

			newValue = Math.min(newValue, max);
			newValue = Math.max(newValue, min);

			setValue(newValue);
		}
	}
}

/*---------------------------------------------*/

RangeSlider.METADATA = {
	name: "RangeSlider",
	props: [
		{
			name: "label",
			type: "string",
			default: "Range slider label",
		},
		{
			name: "valueType",
			type: "string",
			default: "number",
			options: [
				{
					value: "none",
				},
				{
					value: "plain",
				},
				{
					value: "text",
				},
				{
					value: "number",
				},
			],
			category: "value",
		},
		{
			name: "units",
			type: "string",
		},
		{
			name: "min",
			type: "number",
			category: "value",
		},
		{
			name: "max",
			type: "number",
			category: "value",
		},
		{
			name: "value",
			type: "number",
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
		{
			name: "onChange",
			type: "function",
		},
		{
			name: "onSlideEnd",
			type: "function",
		},
	],
};
