import React from "react";

/*---------------------------------------------*/

export default class Component extends React.Component {
	constructor(cfg = {}) {
		super();

		this.cfg = cfg;
		this.id = Math.floor(Math.random() * 10000);

		this.ref = React.createRef();
		this.classNames = [];

		this.onBodyKeydown = this.onBodyKeydown.bind(this);
	}

	componentDidMount() {
		this.cfg.keyboardFocusOnly && document.body.addEventListener("keydown", this.onBodyKeydown);

		let el = this.ref && this.ref.current;
		if (el) {
			var observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === "attributes") {
						if (mutation.attributeName === "tabindex" && el.getAttribute("tabIndex") === "-1") {
							el.blur();
						}
					}
				});
			});

			observer.observe(el, {
				attributes: true,
			});
		}
	}

	componentWillUnmount() {
		this.cfg.keyboardFocusOnly && document.body.removeEventListener("keydown", this.onBodyKeydown);
	}

	onBodyKeydown(event) {
		if (event.key === "Tab") {
			// turn tabClicked on just to indicate that focus needs to be displayed
			this.focusedViaKeyboard = true;
			setTimeout(() => (this.focusedViaKeyboard = false));
		}
	}

	resetClassNames() {
		this.classNames = [];
	}

	addBaseClass() {
		if (this.constructor.BASE_CLASS) {
			this.addClass(this.constructor.BASE_CLASS);
		}
	}

	addClass(className) {
		this.classNames.push(className);
	}

	baseClass(suffix) {
		return this.constructor.BASE_CLASS + suffix;
	}

	applyModifiers(modifiers = []) {
		this.resetClassNames();
		this.addBaseClass();
		modifiers
			.map((modifier) => `${this.constructor.BASE_CLASS}--${this.props[modifier]}`)
			.forEach((className) => {
				this.addClass(className);
			});
	}

	getClassNames() {
		let commonClasses = [];

		this.props.forcehover && commonClasses.push("lth-is-hover");
		this.props.forcefocus && commonClasses.push("lth-is-focus");
		this.props.forceactive && commonClasses.push("lth-is-active");

		this.props.fullwidth && commonClasses.push("lth-is-fullwidth");
		this.props.disabled && commonClasses.push("lth-is-disabled");
		this.props.pressed && commonClasses.push("lth-is-pressed");

		this.props.loading && commonClasses.push("lth-is-loading");

		// disabled component can not have error [Efrat]
		this.props.error && !this.props.disabled && commonClasses.push("lth-has-error");

		let classes = [...this.classNames, ...commonClasses];
		this.props.className && classes.push(this.props.className);

		return classes.join(" ");
	}

	getStyle() {
		let style = { ...this.props.style };
		if (this.props.show === false) {
			style.display = "none";
		}

		// if (this.props['grid-start']) {
		// 	style.gridColumnStart = this.props['grid-start'];
		// }

		// if (this.props['grid-end']) {
		// 	style.gridColumnEnd = this.props['grid-end'];
		// }

		return style;
	}
}
