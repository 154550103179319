import React from 'react';
import {Label,Stack} from '../';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-group';

/*---------------------------------------------*/

export default function Group(props) {
	const customClasses = [];
	props.inline && customClasses.push(`lth-is-inline`);

	const customStyle = {};
	['spacing','rowSpacing','columnSpacing'].forEach((prop) => {
		if (props[prop]) {
			if (typeof props[prop] === 'string') {
				customStyle[`--group-${prop}`] = `var(--group-spacing-${props[prop]})`;
			} else {
				customStyle[`--group-${prop}`] = `${props[prop]}px`;
			}
		}
	});

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props,customStyle);

	const label = props.label && <Label className={`${BASE_CLASS}__label`} disabled={props.disabled}>{props.label}</Label>;

	return (
		<div className={classes} style={style}>
			{label}
			<div className={`${BASE_CLASS}__items`}>
				{props.children}
			</div>
		</div>
	)
}

Group.METADATA = {
	name: 'Group',
	props: [
		{
			name: "inline",
			type: "boolean",
		},
		{
			name: 'spacing',
			type: 'string',
			options: [
				{
					value: 'dense'
				},
				{
					value: 'compact'
				},
				{
					value: 'normal'
				},
				{
					value: 'comfortable'
				},
				{
					value: 'spaced'
				}
			]
		},
		{
			name: 'rowSpacing',
			type: 'string',
			options: [
				{
					value: 'dense'
				},
				{
					value: 'compact'
				},
				{
					value: 'normal'
				},
				{
					value: 'comfortable'
				},
				{
					value: 'spaced'
				}
			]
		},
		{
			name: 'columnSpacing',
			type: 'string',
			options: [
				{
					value: 'dense'
				},
				{
					value: 'compact'
				},
				{
					value: 'normal'
				},
				{
					value: 'comfortable'
				},
				{
					value: 'spaced'
				}
			]
		},
	],
	get demo() {
		return {
			content: [
				<div style={{width: 100, height: 50, backgroundColor: 'var(--color-neutral-300)'}} />,
				<div style={{width: 100, height: 50, backgroundColor: 'var(--color-neutral-300)'}} />,
				<div style={{width: 100, height: 50, backgroundColor: 'var(--color-neutral-300)'}} />,
			]
		}
	}
}
