import React from "react";
import S from "./InfoBlock.module.scss";
import { Heading } from "../../../../lib/components";

export default function InfoBlock() {
	return (
		<div className={S.container}>
			<Heading variant="heading6">title</Heading>
			<Heading variant="heading6">info</Heading>
		</div>
	);
}
