import React,{useState,useEffect} from "react";

import moment from "moment";
import 'moment/locale/en-gb';
import {DateTime} from "luxon";
import DatetimePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { Input, Label } from "../";
import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = "lth-c-datepicker";

/*---------------------------------------------*/

function Datepicker(props) {
	const [value,setValue] = useState();
	const [inputValue,setInputValue] = useState();
	
	useEffect(() => {
		const value = (() => {
			switch (typeof props.value) {
				case 'object': return DateTime.fromObject(props.value);
				case 'string': return DateTime.fromISO(props.value);
			}
	
			return DateTime.fromSeconds(1*props.value/1000);
		})();

		setValue(value);
	},[props.value]);

	const customClasses = [];
	props.openUpwards && customClasses.push('lth-open-upwards');

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);

	if (!value) {
		return null;
	}
	
	const valueStr = props.valueStr || (!value.invalidReason && (props.format ? value.toFormat(props.format) : value.toLocaleString()));
	
	const inputProps = {
		...props,
		value: inputValue || valueStr,
		label: null,
		onChange: onInputChange,
		iconRight: "calendar",
		iconRightColor: "var(--datepicker-icon-color)",
	};

	const label = props.label && <Label tooltip={props.tooltip}>{props.label}</Label>;

	return (
		<div className={classes} style={style}>
			{label}
			<div className={`${BASE_CLASS}__container`}>
				<label className={`${BASE_CLASS}__sublabel`}>{props.sublabel}</label>
				<DatetimePicker
					className={`${BASE_CLASS}__date`}
					value={value && {
						year: value.year,
						month: value.month-1,
						day: value.day
					}}
					timeFormat={false}
					closeOnSelect={true}
					locale="en-gb"
					isValidDate={(currentDate, selectedDate) =>
						props.minDate ? currentDate.isAfter(moment(props.minDate)) : true
					}
					// /* attempt to display currently selected range. kind of works. note - value overrides initialViewDate */
					// initialViewDate={state.hoverDate}
					// renderDay={(props, currentDate, selectedDate) => {
					// 	let dateInsideRange = (props.minDate && state.hoverDate && currentDate.isAfter(props.minDate) && currentDate.isBefore(state.hoverDate));
					// 	props.style = {
					// 		background: dateInsideRange ? 'blue' : ''
					// 	}

					// 	return <td {...props} onMouseOver={() => {
					// 		setState({hoverDate: currentDate})
					// 	}}>{currentDate.date()}</td>;
					// }}
					onChange={onPickerChange}
					renderInput={(props, openPicker, closePicker) => <Input {...inputProps} onClick={openPicker} />}
				/>
			</div>
		</div>
	);

	/*------------------------------------------*/

	function onPickerChange(valueMoment) {
		const dateObject = {
			year: valueMoment.year(),
			month: valueMoment.month()+1,
			day: valueMoment.date()
		}

		const value = DateTime.fromObject(dateObject);

		setValue(value);
		setInputValue(undefined);

		const outputValue = props.outputFormat === 'string' ? value.toISODate() : dateObject;
		props.onChange && props.onChange(outputValue,props.name);
	}

	function onInputChange(value) {
		setInputValue(value);

		const valueMoment = moment(value, props.format);
		if (valueMoment.isValid()) {
			setValue(1*valueMoment);
		}
	}
}

/*---------------------------------------------*/

export default Datepicker;

/*---------------------------------------------*/

Datepicker.METADATA = {
	name: "Datepicker",
	props: [
		{
			name: "label",
			type: "string",
			default: "Enter your input",
		},
		{
			name: "sublabel",
			type: "string",
			category: "view",
		},
		{
			name: "value",
			type: "string",
			default: "",
		},
		{
			name: "valueStr",
			type: "string",
			default: "",
		},
		{
			name: "minDate",
			type: "string",
			default: "",
		},
		{
			name: "placeholder",
			type: "string",
			default: "Enter your input",
		},
		{
			name: "format",
			type: "string",
			category: "view",
		},
		{
			name: "outputFormat",
			type: "string",
			default: "object",
			options: [
				{
					value: "object",
				},
				{
					value: "string",
				},
				// {
				// 	value: "timestamp",
				// },
			],
		},
		{
			name: "openUpwards",
			type: "boolean",
			default: false,
		},
		{
			name: "tooltip",
			type: "string",
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
		{
			name: "error",
			type: "boolean",
			default: false,
		},
		{
			name: "hover",
			type: "boolean",
			default: false,
		},
		{
			name: "focus",
			type: "boolean",
			default: false,
		},
		{
			name: "message",
			type: "string",
		},
		{
			name: "onChange",
			type: "function",
		},
	],
};
