import React from "react";
import "./global.scss";

import { Grid } from "../lib/components";
import Header from "./pilot-components/Header/Header";
import SideMenu from "./pilot-components/SideMenu/SideMenu";
import Settings from "./pilot-components/Settings/Settings";

export default function Pilot() {
	return (
		<Grid rows="min-content 1fr" fluid>
			<Header />
			<SideMenu />
			<Settings />
		</Grid>
	);
}
