import { useMemo } from "react";

/*---------------------------------------------*/

const COMMON_PROPS = ["hover", "focus", "active", "fullwidth", "fullheight", "disabled", "pressed", "loading"];

function useClassBuilder(props, baseClass, customClasses=[], customProps=[]) {
	const classProps = [...customProps, ...COMMON_PROPS];
	const dependencies = Object.entries(props)
		.filter(([prop]) => classProps.includes(prop))
		.map(([_, value]) => value)
		.concat([customClasses]);

	return useMemo(() => {
		const {forcehover,forcefocus,forceactive,fullwidth,fullheight,disabled,pressed,error,...restProps} = props;
		const classes = [baseClass, ...customClasses];

		COMMON_PROPS.forEach((propName) => {
			if (props[propName]) {
				classes.push(`lth-is-${propName}`);
			}
		});

		// disabled component can not have an error
		error && !disabled && classes.push("lth-has-error");

		// allow adding custom className through props
		props.className && classes.push(props.className);

		customProps
			.filter((modifierProp) => (typeof restProps[modifierProp] === "boolean" ? restProps[modifierProp] : true))
			.map((modifierProp) => {
				const modifierValue = restProps[modifierProp];
				const suffix = typeof modifierValue === "boolean" ? modifierProp : modifierValue;

				return `${baseClass}--${suffix}`;
			})
			.forEach((className) => {
				classes.push(className);
			});

		return classes.join(" ");
	}, dependencies);
}

export default useClassBuilder;
