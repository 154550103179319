import React, {useEffect,useState} from 'react';
import {Button,Icon,IconButton,Text} from '../';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-datatable';

/*---------------------------------------------*/

function DataTable({data=[],columns,pageSize=20,...props}) {
	const [pageIndex,setPageIndex] = useState(0);

	const classes = useClassBuilder(props,BASE_CLASS);
	const style = useStyleBuilder(props);

	useEffect(() => {
		if (typeof props.page !== 'undefined') {
			setPageIndex(props.page);
		}
	},[props.page]);

	if (!data) {
		return null;
	}
	
	let rows = data;
	if (props.navigation) {
		rows = rows.slice(pageSize*pageIndex,pageSize*(pageIndex+1));
	}
	
	rows = rows.map((item,index) => {
		return columns.map((column) => column.render ? column.render(item,index,props.name) : item[column.key])
	});
	
	const headingElements = (
		<tr>
			{columns.map((column,index) => (
				<th className={BASE_CLASS+'__table__heading'} key={index} width={column.width}>
					<Text size="small" style={{color: 'inherit'}}>{column.label}</Text>
				</th>
			))}
		</tr>
	);
	
	const pageCount = props.navigation ? Math.ceil(data.length / pageSize) : 0;
	const navigator = props.navigation && (
		<div className={`${BASE_CLASS}__navigator`}>
			<IconButton icon='chevronLeft' disabled={pageIndex <= 0} onClick={onPrevPage} />
			<Text className={`${BASE_CLASS}__navigator__index`}>{pageIndex+1} / {pageCount}</Text>
			<IconButton icon='chevronRight' disabled={pageIndex >= pageCount-1} onClick={onNextPage} />
		</div>
	)
	
	return (
		<div className={classes} style={style}>
			<table className={`${BASE_CLASS}__table`}>
				<thead key="head">
					{headingElements}
				</thead>
				<tbody key="body">
					{rows.map((row,index) => <DataTableRow data={row} wrapAround={props.wrapAround} key={index} />)}
				</tbody>
			</table>
			{navigator}
		</div>
	)

	/*----------------------------------------------------*/

	function onNextPage() {
		const newPageIndex = pageIndex+1;

		setPageIndex(newPageIndex);
		props.onPageChanged?.apply(null,[newPageIndex]);
	}

	function onPrevPage() {
		const newPageIndex = pageIndex-1;

		setPageIndex(newPageIndex);
		props.onPageChanged?.apply(null,[newPageIndex]);
	}
}

function DataTableRow(props) {
	return (
		<tr className={BASE_CLASS+'__table__row'}>
			{props.data.map((cell,index) => <DataTableCell data={cell} wrapAround={props.wrapAround} key={index} />)}
		</tr>
	)
}

function DataTableCell(props) {
	const cellBaseClass = `${BASE_CLASS}__table__value`;
	const classes = [cellBaseClass];
	if (props.wrapAround) {
		classes.push(`${cellBaseClass}--wraparound`);
	}

	const content = typeof props.data === 'string' ? <Text size="small">{props.data}</Text> : props.data;
	
	return (
		<td className={classes.join(' ')}>{content}</td>
	)
}

/*---------------------------------------------*/

DataTable.METADATA = {
	name: 'DataTable',
	props: [
		{
			name: 'columns'
		},
		{
			name: 'render'
		},
		{
			name: 'data'
		},
		{
			name: 'wrapAround',
			type: 'boolean',
		},
		{
			name: 'navigation',
			type: 'boolean',
		},
		{
			name: 'pageSize',
			type: 'number',
			default: 20,
		},
		{
			name: 'page',
			type: 'number',
		},
		{
			name: 'onPageChanged',
			type: 'function',
		},
	],
	demo: {
		props: {
			// show: false,
			columns: [
				{
					key: 'date',
					label: 'Date',
					render: (item) => "" + new Date(item.date)
				},
				{
					key: 'title',
					label: 'Title'
				},
				{
					key: 'description',
					label: 'Description',
					width: '50%',
				},
				{
					key: null,
					label: '',
					render: (item) => <Icon size={14} icon='edit' style={{verticalAlign: 'initial'}} />
				},
			],
			data: (() => {
				const start = 1*new Date();
				const rnd = (min,max) => Math.floor(min+Math.random()*(1+max-min));
				
				const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
				return [...Array(100)].map((_,index) => ({
					title: `Event ${index+1}`,
					date: start + index*60*60,
					description: loremIpsum.split(' ').slice(0,rnd(10,50)).join(' '),
				}));
			})()
		}
	},
	content: {
		default: {

		}
	}
}

/*---------------------------------------------*/

export default DataTable;

/*---------------------------------------------*/