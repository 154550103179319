import React, { useState, useRef, useMemo } from "react";
import "react-notifications/lib/notifications.css";
import { useDrag, useDrop } from "react-dnd";
import * as DesignSystem from "../../lib/components";

const EditableComponent = (props) => {
	const ref = useRef(null);
	const component = props.component;
	const ComponentClass = getByPath(DesignSystem, component.type);
	let metadata = ComponentClass.METADATA;
	let [, setHover] = useState(false);

	const [{ opacity }, drag] = useDrag({
		item: {
			type: "component",
			component: component,
		},
		collect: (monitor) => ({
			opacity: monitor.isDragging() ? 0.5 : 1,
		}),
	});

	const [{ isOver, }, drop] = useDrop({
		accept: "component",
		drop: (dragged, monitor) => {
			if (!ref.current) {
				return;
			}

			const boundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (boundingRect.bottom - boundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - boundingRect.top;
			const above = hoverClientY < hoverMiddleY;
			debugger;
			props.onMove(props.component, dragged.component, above);
		},
		// canDrop: () => canMoveKnight(x, y),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
			canDrop: !!monitor.canDrop(),
		}),
	});

	const classes = useMemo(() => {
		let classes = ["editableComponent"];
		isOver && classes.push("drop-hover");
		props.selectedInEditor && classes.push("is-selected");

		return classes;
	}, [isOver, props.selectedInEditor]);

	drag(drop(ref));

	let componentProps = getComponentProps(metadata);
	let content =
		(props.children?.length && props.children) ||
		(metadata.demo && metadata.demo.content) ||
		(metadata.content && metadata.content.default);

	return (
		<div
			className={classes.join(" ")}
			style={{ ...props.style, ...opacity }}
			name={metadata.name}
			onClick={onClick}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			ref={ref}
		>
			<ComponentClass {...componentProps}>{content}</ComponentClass>
			<div className="interaction-shield"></div>
		</div>
	);

	/*------------------------------------------*/

	function onClick(event) {
		event.stopPropagation();
		props.onSelected();
	}

	function onMouseOver(event) {
		event.stopPropagation();
		setHover(true);
	}

	function onMouseOut(event) {
		setHover(false);
	}

	/*------------------------------------------*/

	function getComponentProps(metadata) {
		let componentProps = (metadata.demo && metadata.demo.props) || [];

		metadata.props &&
			metadata.props.forEach((prop) => {
				if (prop.type === "jsx") {
					componentProps[prop.name] = <div dangerouslySetInnerHTML={{ __html: component.props[prop.name] }} />;
				} else if (component.props[prop.name] !== undefined) {
					componentProps[prop.name] = component.props[prop.name];
				} else if (metadata.demo && metadata.demo.props && metadata.demo.props[prop.name]) {
					componentProps[prop.name] = metadata.demo.props[prop.name];
				}

				if (componentProps[prop.name] === "null") {
					componentProps[prop.name] = null;
				}
			});

		return componentProps;
	}
	/*------------------------------------------*/
};

function getByPath(object, path) {
	return path.split(".").reduce((res, prop) => res[prop], object);
}

export default EditableComponent;
