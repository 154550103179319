import React from 'react';
import Component from '../component.js';

/*---------------------------------------------*/

export default class Divider extends Component {
	static BASE_CLASS = 'lth-c-divider';


	constructor() {
		super();

		this.state = {
		}

		this.onClick = this.onClick.bind(this);
	}
	
	onClick(e) {
	}

	render() {
		this.resetClassNames();
		this.addClass(this.constructor.BASE_CLASS);
	
		let label = this.props.label && <div className={this.constructor.BASE_CLASS+'__label'}>{this.props.label}</div>
		
		return (
			<div style={this.getStyle()} className={this.getClassNames()}>
				<div className={this.constructor.BASE_CLASS+'__line'} />
				<div>{label}</div>
				<div className={this.constructor.BASE_CLASS+'__line'} />
			</div>
		);
	}
}

/*---------------------------------------------*/

Divider.METADATA = {
	name: 'Divider',
	props: [
		{
			name: 'label',
			type: 'string'
		}
	]
}