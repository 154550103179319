import React from "react";
import S from "./Settings.module.scss";
import TopButtons from "./TopButtons/TopButtons";
import TopTabs from "./TopTabs/TopTabs";

export default function Settings() {
	return (
		<div className={S.container}>
			<TopButtons />
			<TopTabs />
		</div>
	);
}
