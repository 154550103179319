import React,{useRef,useLayoutEffect} from 'react';
import { Icon, Text } from '../';

import useClassBuilder from 'lib/hooks/useClassBuilder';
import useStyleBuilder from 'lib/hooks/useStyleBuilder';

/*---------------------------------------------*/

const BASE_CLASS = 'lth-c-overflowmenu__item';

/*---------------------------------------------*/

function OverflowMenuItem(props) {
	const ref = useRef(null);
	const label = props.label || props.children;
	const icon = props.icon && <Icon size={16} icon={props.icon} className={BASE_CLASS+'__icon'} />
	
	const customClasses = [];
	props.selected && customClasses.push('lth-is-selected');
	props.noPadding && customClasses.push(`${BASE_CLASS}--no-padding`);

	useLayoutEffect(() => {
		if (props.selected && ref.current) {
			ref.current.scrollIntoView();
		}
	},[]);

	const content = (() => {
		if (typeof label !== 'string') {
			return label;
		}

		return [icon, <Text size={props.size === 'slim' ? 'small' : 'regular'} disabled={props.disabled}>{label}</Text>]
	})();

	const classes = useClassBuilder(props,BASE_CLASS,customClasses);
	const style = useStyleBuilder(props);
	
	return (
		<div className={classes} style={style} onClick={onClick} onKeyDown={onKeyDown} tabIndex={0} ref={ref}>
			{content}
		</div>
	)

	/*------------------------------------------*/

	function onClick(e) {
		props.onClick && props.onClick(props.index,props.value)
	}

	function onKeyDown(e) {
		if (e.key === " ") {
			props.onClick && props.onClick(props.index,props.value)
		}
	}
}

OverflowMenuItem.METADATA = {
	name: 'OverflowMenu.Item',
	props: [
		{
			name: 'label',
			type: 'string',
			default: 'Label',
		},
		{
			name: 'icon',
			type: 'string',
		},
		{
			name: "disabled",
			type: "boolean",
			default: false,
		},
	],
}

/*---------------------------------------------*/

export default OverflowMenuItem;

/*---------------------------------------------*/
