import React from "react";
import S from "./AddNewEvent.module.scss";
import { Button, Heading } from "../../../../../lib/components";
import { AboutYourEventSection, DateAndTimeSection, GeneralInfoSection, LocationSection } from "./sections";

export default function AddNewEvent() {
	function sayClick(type) {
		alert(`clicked on ${type.toUpperCase()}`);
	}

	return (
		<div className={S.container}>
			<Heading variant="heading4">Add event</Heading>
			<GeneralInfoSection />
			<DateAndTimeSection />
			<LocationSection />
			<AboutYourEventSection />
			<div className={S.publishButtons}>
				<Button onClick={() => sayClick("publish")} variant="primary">
					Publish
				</Button>
				<Button onClick={() => sayClick("cancel")} variant="common">
					Cancel
				</Button>
			</div>
		</div>
	);
}
