import React from "react";
import S from "./SideMenu.module.scss";

import { Divider } from "../../../lib/components";
import InfoBlock from "./InfoBlock/InfoBlock";
import MenuOptions from "./MenuOptions/MenuOptions";

export default function SideMenu() {
	return (
		<div className={S.container}>
			<InfoBlock />
			<Divider />
			<MenuOptions />
		</div>
	);
}
