import React, { useState } from "react";
import "./editor.scss";

import PropsEditor from "../propsEditor";
import Navigator from "./navigator";
import Element from "./element";
import * as DesignSystem from "../../lib/components";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

/*---------------------------------------------*/

const START_PAGE = {
	components: [
		// {
		// 	id: generateId('component'),
		// 	type: 'Button',
		// 	props: {},
		// 	style: {},
		// },
		// {
		// 	id: generateId('component'),
		// 	type: 'Input',
		// 	props: {},
		// 	style: {},
		// },
		{
			id: generateId("component"),
			type: "Stack",
			props: {},
			style: {},
			components: [
				{
					id: generateId("component"),
					type: "Select",
					props: {},
					style: {},
					components: [
						{
							id: generateId("component"),
							type: "Select.Option",
							props: {
								label: "Option 1",
							},
							style: {},
						},
					],
				},
				{
					id: generateId("component"),
					type: "RadioButtonGroup",
					props: {},
					style: {},
					components: [
						{
							id: generateId("component"),
							type: "RadioButton",
							props: {},
							style: {},
						},
						{
							id: generateId("component"),
							type: "RadioButton",
							props: {},
							style: {},
						},
					],
				},
			],
		},
	],
};

const STYLE_PARAMS = [
	{
		name: "display",
		type: "string",
		default: "block",
		options: [
			{
				value: "inline-block",
			},
			{
				value: "block",
			},
		],
	},
	{
		name: "margin",
		type: "number",
	},
];

/*---------------------------------------------*/

const Editor = (_props) => {
	const storedData = JSON.parse(localStorage["page"] || "null");
	const [page, setPage] = useState(storedData || START_PAGE);
	const [selected, setSelected] = useState(null);
	const [canvasBackground, setCanvasBackground] = useState(null);

	addParents(page);

	let colorOptions = ["#fff", "#fafafa", "#888", "#333"].map((color) => {
		return <div style={{ background: color }} onClick={() => setCanvasBackground(color)} key={color}></div>;
	});

	const Elements = generateElements(page, selected);
	let components = Object.entries(DesignSystem);

	return (
		<>
			<div className="editor" key="editor">
				<div className="toolbar-sidebar">
					<DesignSystem.Dropdown label="Add component">
						{components.map(([name, _component]) => (
							<DesignSystem.Dropdown.Option onClick={() => addComponent(page, selected, name)}>
								{name}
							</DesignSystem.Dropdown.Option>
						))}
					</DesignSystem.Dropdown>
				</div>
				<div className="sidebar">
					<DndProvider backend={HTML5Backend}>
						<Navigator
							node={page}
							selected={selected}
							onSelected={setSelected}
							onMove={moveComponent}
							onDelete={deleteComponent}
						/>
					</DndProvider>
				</div>
				<div className="canvas-toolbar">
					<div className="colorOptions">{colorOptions}</div>

					<div className="actions">
						<DesignSystem.Button>Preview</DesignSystem.Button>
					</div>
				</div>
				<div className="canvas" style={{ backgroundColor: canvasBackground }} onClick={() => setSelected(null)}>
					<DndProvider backend={HTML5Backend}>{Elements}</DndProvider>
				</div>
				<div className="sidebar" style={{ padding: 16 }}>
					{getPropsSidebar(selected)}
				</div>
			</div>
		</>
	);

	/*---------------------------------------------*/

	function getByPath(object, path) {
		return path.split(".").reduce((res, prop) => res[prop], object);
	}

	/*---------------------------------------------*/

	function getPropsSidebar(component) {
		if (!component) {
			return <div style={{ marginTop: 16, marginLeft: 8 }}>No component selected</div>;
		}

		let metadata = getByPath(DesignSystem, component.type).METADATA;
		return (
			<DesignSystem.Tabs>
				<DesignSystem.Tabs.Tab label="PROPS">
					<PropsEditor
						params={metadata?.props}
						values={component?.props || {}}
						onChange={(values) => onPropsChange("props", values)}
						key={selected?.id}
					/>
				</DesignSystem.Tabs.Tab>
				<DesignSystem.Tabs.Tab label="LAYOUT">
					<PropsEditor
						params={STYLE_PARAMS}
						values={component?.style || {}}
						onChange={(values) => onPropsChange("style", values)}
						key={selected?.id}
					/>
				</DesignSystem.Tabs.Tab>
			</DesignSystem.Tabs>
		);
	}

	/*---------------------------------------------*/

	function generateElements(node, selected) {
		if (!node.components) {
			return null;
		}

		return node.components.map((component) => {
			const isSelected = selected && component.id === selected.id;

			const ElementProps = {
				id: component.id,
				component: component,
				values: component.props,
				style: component.style,
				selectedInEditor: isSelected, // 'selected' prop is overriden by some components, like RadioButtonGroup
				onSelected: (event) => {
					event && event.stopPropagation();
					setSelected(component);
				},
				onMove: moveComponent,
			};

			return <Element {...ElementProps}>{generateElements(component, selected)}</Element>;
		});
	}

	/*---------------------------------------------*/

	function addParents(tree) {
		if (!tree.components) {
			return;
		}

		tree.components.forEach((component) => {
			component._parent = tree;
			addParents(component);
		});
	}

	function addComponent(page, parent, name) {
		if (!parent) {
			parent = page;
		}

		parent.components = parent.components || [];
		parent.components.push({
			id: generateId("component"),
			type: name,
			props: {},
			style: {
				display: "block",
			},
		});

		updatePage(page);
	}

	function moveComponent(target, source, above) {
		const sourceIndex = source._parent.components.findIndex((component) => component.id === source.id);
		source._parent.components.splice(sourceIndex, 1);

		const targetIndex = target._parent.components.findIndex((component) => component.id === target.id);
		target._parent.components.splice(above ? targetIndex : targetIndex + 1, 0, source);

		updatePage(page);
	}

	function deleteComponent(component) {
		const parent = component._parent;
		parent.components = parent.components.filter((c) => c.id !== component.id);
		updatePage(page);
	}

	function updatePage(page) {
		const updatedPage = { ...page, ...{ _version: (page._version || 0) + 1 } };
		setPage(updatedPage);

		localStorage["page"] = JSON.stringify(updatedPage, (k, v) => (k.startsWith("_") ? undefined : v));
	}

	function onPropsChange(key, values) {
		selected[key] = values;
		updatePage(page);
	}
};

export default Editor;

/*---------------------------------------------*/

function generateId(prefix = "", length = 21) {
	let id = "";
	let chars = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	for (let i = 0; i < length; i++) {
		id += chars[Math.floor(Math.random() * chars.length)];
	}

	prefix && (id = [prefix, id].join("_"));
	return id;
}

/*---------------------------------------------*/
